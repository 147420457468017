import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RWAConsole from "./components/RWAConsole/RWAConsole";
import CsvFilePreview from "./components/UploadDownload/uploadCSVpreview";
import ReviewFormat from "./components/UploadDownload/reviewFormat";
import DataDashboard from "./components/UploadDownload/DataDashboard";
import Header from "./components/header/Header";
import Outdoor from "./components/mediaData/Outdoor";
import Home from "./components/home/Home";
import Services from "./components/mediaData/Services";
import MediaDataFile from "./components/mediaData/MediaDataBulkUpload";
import MediaDataForm from "./components/mediaData/MediaDataForm";
import UploadMenu from "./components/mediaData/UploadMenu";
import FileUpload from "./components/mediaData/ImageFileUpload";
import VendorRegistrationForm from "./components/Vendors/VendorRegistrationForm";
import VendorList from "./components/Vendors/VendorsList";
import VendorsMenu from "./components/Vendors/VendorsMenu";
import SignUpPage from "./components/SignUpPage";
import GoToEmailInbox from "./components/GoToEmailInbox";
import LoginPage from "./components/LoginPage";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ResetPassword from "./components/PasswordComponent/ResetPassword";
import VendorProfile from "./components/Vendors/VendorProfile";
import VehicleRCValidation from "./components/VehicleRCValidation/VehicleRCValidation";
import PrivateRoutes from "./components/PrivateRoutes";
import ExternalRoutes from "./components/ExternalRoutes";
import InternalRoutes from "./components/InternalRoutes";
import DigitalScreenMediaUpload from "./components/digital/DigitalScreenMediaUpload";
import CinemaMediaUpload from "./components/cinema/CinemaMediaUpload";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Header />}>
          <Route path="/" element={<Home />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/go-to-email-inbox" element={<GoToEmailInbox />} />
          <Route path="/verify-email/token/:token" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/reset-password/token/:token"
            element={<ResetPassword />}
          />
          {/* <Route path="rwaConsole" element={<RWAConsole />} /> */}
          <Route element={<PrivateRoutes />}>
            <Route element={<InternalRoutes />}>
              <Route path="home" element={<Home />} />
              <Route path="rwaConsole" element={<RWAConsole />} />
              <Route path="data-dashboard" element={<DataDashboard />} />
              <Route path="upload-data" element={<CsvFilePreview />} />
              <Route path="view-format" element={<ReviewFormat />} />
              <Route path="digital-screen" element={<DigitalScreenMediaUpload/>} />
              <Route path="cinema-upload" element={<CinemaMediaUpload/>} />
              <Route path="upload">
                <Route path="" element={<UploadMenu />} />
                <Route path="fileUpload" element={<FileUpload />} />
                <Route path="mediaDataForm" element={<MediaDataForm />} />
                <Route path="mediaDataFile" element={<MediaDataFile />} />
              </Route>
              <Route path="services">
                <Route path="" element={<Services />} />
                <Route path="outdoor">
                  <Route path="" element={<Outdoor />} />
                </Route>
              </Route>
              <Route path="vendors">
                <Route path="" element={<VendorsMenu />} />
                <Route
                  path="registerVendor"
                  element={<VendorRegistrationForm />}
                />
                <Route
                  path="registerVendor/:vendorId"
                  element={<VendorRegistrationForm />}
                />
                <Route path="vendorsList" element={<VendorList />} />
                <Route
                  path="vendorProfile/:vendorId"
                  element={<VendorProfile />}
                />
              </Route>
            </Route>
            <Route element={<ExternalRoutes />}>
              <Route
                path="rcValidation"
                element={<VehicleRCValidation />}
              ></Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
