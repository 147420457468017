import React from "react";
import { Button, Container, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";

interface DatasetControlsProps {
  datasetName: string;
}

const DatasetControls: React.FC<DatasetControlsProps> = ({ datasetName }) => {
  const history = useNavigate();

  const redirectToViewFormat = () => {
    history(`/view-format`, {
      state: { datasetName: datasetName },
    });
  };

  const redirectToUploadCSV = () => {
    history(`/upload-data`, {
      state: { datasetName: datasetName },
    });
  };

  return (
    <>
      <Container>
        <Typography>{datasetName}</Typography>
        <Button
          variant="outlined"
          sx={{ margin: "5px" }}
          onClick={() => redirectToUploadCSV()}
        >
          Upload
          <CloudUploadIcon sx={{ marginLeft: "5px" }} />
        </Button>
        <Button
          variant="outlined"
          sx={{ margin: "5px" }}
          onClick={() => redirectToViewFormat()}
        >
          View format
        </Button>
      </Container>
    </>
  );
};

const DataDashboard = () => {
  return (
    <Container>
      <Typography sx={{marginBottom: "30px"}}>
        <strong>Upload Data:</strong>
        <br />
        Upload your data in three different formats:
        <br />
        - Base Data: This format is suitable for initial data uploads.
        <br />
        - Extended Data: Use this format for additional data that extends the
        base dataset.
        <br />
        - Finalized Data: Upload your finalized data in this format.
        <br />
        Simply select the appropriate format and upload your CSV file.
        <br />
        <br />
        <strong>View Format:</strong>
        <br />
        Before uploading your data, you can preview the format of each dataset:
        <br />
        - Base Data Format: View the structure and requirements for the base
        dataset and you can download the format.
        <br />
        - Extended Data Format: Understand the format needed for extending the
        base dataset and you can download the format.
        <br />- Finalized Data Format: Preview the format required for finalized
        data and you can download the format.
      </Typography>

      <DatasetControls datasetName="baseData" />

      <DatasetControls datasetName="extendedData" />

      <DatasetControls datasetName="finalizedData" />
    </Container>
  );
};

export default DataDashboard;
