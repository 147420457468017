import {
  Grid,
  TextField,
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  Modal,
} from "@mui/material";
import { useState } from "react";

interface RenderTextFieldProps {
  label: string;
  value: string | number | null;
}

export const RenderTextField: React.FC<RenderTextFieldProps> = ({
  label,
  value,
}) => (
  <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
    <TextField
      label={label}
      value={value}
      fullWidth
      variant="outlined"
      InputProps={{
        readOnly: true,
      }}
    />
  </Grid>
);

interface RenderFileFieldProps {
  label: string;
  file: File | string | null;
}

export const RenderFileField: React.FC<RenderFileFieldProps> = ({
  label,
  file,
}) => {
  const [open, setOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [contentType, setContentType] = useState<string | null>(null);

  const handleOpen = async () => {
    if (file) {
      try {
        const response = await fetch(file as string);
        const contentType = response.headers.get("Content-Type");
        setContentType(contentType || "application/octet-stream");
        setFileUrl(file as string);
        setOpen(true);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFileUrl(null);
    setContentType(null);
  };

  return (
    <>
      <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 1,
            border: "1px solid #FFC702",
            borderRadius: "4px",
          }}
        >
          <Typography sx={{ flex: 1, margin: 0.8 }}>{label}:</Typography>
          {file ? (
            <Button variant="contained" onClick={handleOpen}>
              View
            </Button>
          ) : (
            <Typography>No file uploaded</Typography>
          )}
        </Box>
      </Grid>

      <VendorProfileFilePreviewModal
        open={open}
        fileUrl={fileUrl}
        contentType={contentType}
        onClose={handleClose}
      />
    </>
  );
};

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Paper
    elevation={3}
    sx={{
      marginBottom: 4,
      padding: 2,
      border: "0.005px solid #FFC702",
    }}
  >
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Divider sx={{ marginBottom: 3 }} />
    {children}
  </Paper>
);

interface VendorProfileFilePreviewModalProps {
  open: boolean;
  fileUrl: string | null;
  contentType: string | null;
  onClose: () => void;
}

export const VendorProfileFilePreviewModal = ({
  open,
  fileUrl,
  contentType,
  onClose,
}: VendorProfileFilePreviewModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "85%",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "700px",
          }}
        >
          {contentType?.startsWith("image/") ? (
            <img
              src={fileUrl || ""}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          ) : contentType?.startsWith("application/pdf") ? (
            <iframe
              src={fileUrl || ""}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Preview"
            />
          ) : (
            <Typography>Unsupported file type</Typography>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 30,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button size="large" variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
