import {
    VendorBankDetails,
    VendorBasicInformation,
    VendorBusinessAndFinance,
    VendorOtherDocuments,
    VendorTaxAndCompliance
} from './vendorTypes';

type VendorTypes =
    | VendorBankDetails
    | VendorBasicInformation
    | VendorBusinessAndFinance
    | VendorOtherDocuments
    | VendorTaxAndCompliance;

interface HandleEmptyStringValuesProps<T> {
    values: T;
    setValue: React.Dispatch<React.SetStateAction<T>>;
}

const useHandleEmptyStringValues = <T extends VendorTypes>({ values, setValue }: HandleEmptyStringValuesProps<T>) => {
    Object.entries(values).forEach(([key, value]) => {
        if (typeof value === 'string' && value === "") {
            setValue(prevValues => {
                const updatedValues = { ...prevValues };
                (updatedValues as any)[key] = null;
                return updatedValues as T;
            });
        }
    });
    return;
};

export default useHandleEmptyStringValues;
