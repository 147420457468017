import { AuthApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function newPassword(password: string, token: string) {
    const url = `${AuthApiBaseUrl}/newPassword?resetToken=${token}`;
    return fetchWrapper( url,{
        method: 'PATCH',
        body: {password},
    });
}
