import React, { useState } from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SignUpButton from "../SignUpButton";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { camelCase } from "../../utils/camelCase";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import { ProfileData } from "./ProfileValues";
import { useAuthContext } from "../../providers/AuthProvider";
import { CircleOutlined as CircleOutlinedIcon } from "@mui/icons-material";

interface NavigationProps {
  profileData?: ProfileData | null;
}

const ProfileMenu: React.FC<NavigationProps> = ({ profileData }) => {
  const history = useNavigate();
  const { isAuthenticated, logout } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNavigation = (destination: string) => {
    const currentPath = window.location.pathname;

    if (currentPath === destination) {
      window.location.reload();
    } else {
      history(destination);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout({ returnTo: "/" });
  };

  const handleProfileClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNavigation("/profile");
    window.scrollTo(0, 0);
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                minWidth: "150px",
                borderRadius: "7px",
                boxShadow: "0px 0px 5px 0px #99dfff",
              },
            },
          }}
        >
          {isAuthenticated && (
            <MenuItem
              onClick={(e: any) => {
                handleProfileClick(e);
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#F0F7FF",
                  borderRadius: "10px",
                },
              }}
            >
              My Profile
              <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
            </MenuItem>
          )}
          {isAuthenticated && (
            <MenuItem
              onClick={handleLogout}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#F0F7FF",
                  borderRadius: "10px",
                },
              }}
            >
              Logout
              <LogoutIcon sx={{ fontSize: "20px" }} />
            </MenuItem>
          )}
        </Menu>
        {isAuthenticated && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minWidth: "max-content",
            }}
            className="profile-website"
          >
            <Button
              onClick={handleClick}
              sx={{
                textTransform: "none",
                color: "white",
                fontSize: "18px",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "16px",
                }}
              >
                <CircleOutlinedIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "40px",
                  }}
                />
                <PersonIcon
                  sx={{ fontSize: "20px", zIndex: 1, color: "white" }}
                />
              </Box>
              {profileData
                ? `${camelCase(profileData.firstName)} ${
                    profileData.lastName ? camelCase(profileData.lastName) : ""
                  }`
                : "Guest User"}
              <ExpandMoreIcon sx={{ marginLeft: "5px" }} />
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minWidth: "max-content",
          }}
        >
          {!isAuthenticated && <SignUpButton />}
        </Box>
      </Box>
    </>
  );
};

export default ProfileMenu;
