import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface CustomSplitButtonProps {
  onDownloadAll: () => void;
  onDownloadSelected: () => void;
  onDeleteSelected: () => void;
}

const CustomSplitButton: React.FC<CustomSplitButtonProps> = ({
  onDownloadAll,
  onDownloadSelected,
  onDeleteSelected,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("Download All");
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: string
  ) => {
    setSelectedOption(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    switch (selectedOption) {
      case "Download All":
        onDownloadAll();
        break;
      case "Download Selected":
        onDownloadSelected();
        break;
      case "Delete Selected":
        onDeleteSelected();
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} aria-label="Button group with a nested menu">
        <Button
          sx={{
            width: "210px",
            color: "",
            backgroundColor: "white",
            border: "1px solid ",
            padding: "10px 20px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "",
              color: "white",
              border: "1px solid ",
            },
          }}
          onClick={handleButtonClick}
          endIcon={
            selectedOption === "Delete Selected" ? <DeleteOutlineIcon /> : null
          }
        >
          <span style={{ textAlign: "center", width: "100%" }}>
            {selectedOption}
          </span>
        </Button>
        <Button
          style={{
            color: "",
            backgroundColor: "white",
            border: "1px solid ",
            padding: "10px 20px",
            cursor: "pointer",
          }}
          size="small"
          aria-label="select action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {["Download All", "Download Selected", "Delete Selected"].map(
                    (option) => (
                      <MenuItem
                        key={option}
                        onClick={(event) => handleMenuItemClick(event, option)}
                      >
                        {option}
                        {option === "Delete Selected" && (
                          <DeleteOutlineIcon sx={{ fontSize: "20px" }} />
                        )}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default CustomSplitButton;
