function handleResponse(response: Response) {
    if (!response.ok) {
        const error = { status: response.status, statusText: response.statusText, errorCause: '' };
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.indexOf('application/json') > -1) {
            return response.json().then((json) => {
                error.errorCause = json.errorCause;
                return Promise.reject(error);
            });
        }
        // return response.text().then((text) => (console.log({text})));
        return Promise.reject(error);
    }
    try {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.indexOf('application/json') > -1) {
            return response.json().then((json) => ({ data: json, headers: response.headers }));
        }
        return response.text().then((text) => ({ data: text, headers: response.headers }));
    } catch {
        return Promise.reject(new Error('Could not parse the response'));
    }
}

interface CommonRequestOptions {
    headers?: Record<string, string>;
    query?: Record<string, string> | string;
    signal?: AbortSignal;
    method: string;
}

interface NoPayloadRequestOptions extends CommonRequestOptions {
    method: 'GET' | 'DELETE' | 'HEAD';
}

interface PayloadRequestOptions extends CommonRequestOptions {
    method: 'POST' | 'PATCH' | 'PUT';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
}

type RequestOptions = NoPayloadRequestOptions | PayloadRequestOptions;

export function fetchWrapper<T = unknown>(path: string, options: RequestOptions) {
    const { method, headers, query, signal } = options;
    const reqOptions: RequestInit = { method, headers };

    if (signal) {
        reqOptions.signal = signal;
    }

    if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
        const { body } = options;

        if (body instanceof FormData) {
            reqOptions.body = body;
        } else if (typeof body === 'object') {
            reqOptions.body = JSON.stringify(body);
            reqOptions.headers = {
                ...reqOptions.headers,
                'Content-Type': 'application/json',
            };
        }
    }

    const queryParams = query ? new URLSearchParams(query).toString() : '';

    return fetch(`${path}${queryParams ? `?${queryParams}` : ''}`, reqOptions).then(
        (response) => handleResponse(response) as Promise<{
            ok: any;
            json(): any;
            data: T;
            headers: Headers;
        }>
    );
}
