import React, { useState, ChangeEvent } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { fileParser } from "../mediaData/MediaDataUtils";
import * as XLSX from "xlsx";
import theme from "../../theme";
import ClearIcon from "@mui/icons-material/Clear";

import { v4 as uuidv4 } from "uuid";
import { verifyVehicleNumber } from "../../clients/verifyVehicleNumber";
import {
  modifyTableDataKeys,
  convertArrayToHumanReadable,
  toHumanReadableFormat,
  rearrangeData,
  desiredColumnOrder,
} from "./utils";

export interface TableData {
  [key: string]: any;
}

const VehicleRCValidation: React.FC = () => {
  const [previewData, setPreviewData] = useState<TableData[]>([]);
  const [previewColumns, setPreviewColumns] = useState<string[]>([]);
  const [resultData, setResultData] = useState<TableData[]>([]);
  const [resultColumns, setResultColumns] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "not-initiated" | "initiated" | "completed"
  >("not-initiated");
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    handleClearAll();
    setLoading(true);
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      try {
        const { filteredData } = await fileParser(uploadedFile);

        const header = filteredData[0];
        const dataRows = filteredData.slice(1);

        const dataObjects = dataRows.map((row) => {
          const obj: TableData = {};
          header.forEach((col, index) => {
            let cellValue = row[index];

            if (typeof cellValue === "string") {
              cellValue = cellValue.replace(/-/g, "").trim();
            }

            obj[col] = cellValue;
          });
          return obj;
        });

        setPreviewData(dataObjects);
        setPreviewColumns(header);
      } catch (error) {
        console.error("Error parsing file:", error);
      }
    }
    event.target.value = "";
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleUpload = async () => {
    if (!file) return;

    const groupId = uuidv4();
    setUploadStatus("initiated");

    const tempResultData: TableData[] = [];
    const tempResultColumnsSet = new Set<string>();

    for (const row of previewData) {
      try {
        const vehicleNumber = row["Vehicle Number"];
        const response: any = await verifyVehicleNumber({
          groupId,
          vehicleNumber,
        });
        const vehicleData = response.data.result.extraction_output;
        vehicleData["RC Number"] = response.data.rcNumber;

        // Update temporary data and columns
        tempResultData.push(vehicleData);
        Object.keys(vehicleData).forEach((key) =>
          tempResultColumnsSet.add(key)
        );

        // Update state incrementally
        const currentColumns = Array.from(tempResultColumnsSet);
        const filteredColumns = desiredColumnOrder.filter((col) =>
          currentColumns.includes(col)
        );
        const filteredData = rearrangeData(tempResultData, filteredColumns);

        setResultData(filteredData);
        setResultColumns(filteredColumns);
      } catch (error) {
        console.error("Error uploading row:", row, error);

        // Add error row to temporary data
        tempResultData.push({ ...row, error: "Failed to process" });

        // Update state incrementally
        const currentColumns = Array.from(tempResultColumnsSet);
        const filteredColumns = desiredColumnOrder.filter((col) =>
          currentColumns.includes(col)
        );
        const filteredData = rearrangeData(tempResultData, filteredColumns);

        setResultData(filteredData);
        setResultColumns(filteredColumns);
      }
    }

    setUploadStatus("completed");
    setDialogOpen(true);
  };

  const exportToExcel = () => {
    console.log({ resultData });
    const ws = XLSX.utils.json_to_sheet(modifyTableDataKeys(resultData), {
      header: convertArrayToHumanReadable(resultColumns),
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Processed Data");
    XLSX.writeFile(wb, "processed_data.xlsx");
  };

  const handleClearAll = () => {
    setFile(null);
    setPreviewData([]);
    setPreviewColumns([]);
    setResultData([]);
    setResultColumns([]);
    setUploadStatus("not-initiated");
  };

  return (
    <Box
      sx={{
        padding: 3,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <Box sx={{ display: "flex", width: "1000px", minHeight: "50px" }}>
          <Button
            component="label"
            fullWidth
            variant="outlined"
            disabled={uploadStatus === "initiated"}
            sx={{
              borderRadius: "35px 0 0 35px",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            {file ? (
              file.name
            ) : (
              <strong>No file selected. Click here to select a file</strong>
            )}
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={handleFileChange}
              hidden
            />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            // startIcon={<UploadIcon />}
            disabled={!file || loading || uploadStatus === "initiated"}
            sx={{
              borderRadius: "0 35px 35px 0",
              cursor: uploadStatus === "completed" ? "not-allowed" : "pointer",
              whiteSpace: "nowrap",
            }}
          >
            Fetch Details
          </Button>
        </Box>

        <Button
          variant="outlined"
          color="error"
          onClick={handleClearAll}
          startIcon={<ClearIcon />}
          disabled={!file || uploadStatus === "initiated"}
          sx={{
            ml: 2,
            borderRadius: "35px 35px 35px 35px",
          }}
        >
          Clear All
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Box
          sx={{
            flex: 1,
            borderRadius: 2,
            overflow: "hidden",
            boxShadow: 1,
            maxWidth: "200px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              bgcolor: theme.palette.primary.dark,
              color: "#fff",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 10px 0 0",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                p: 2,
                bgcolor: theme.palette.primary.dark,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Preview Data
            </Typography>
          </Box>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="65vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                height: "650px",
                overflow: "auto", // Enable both horizontal and vertical scroll
                textAlign: "center",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {previewColumns.map((column) => (
                      <TableCell
                        key={column}
                        sx={{
                          fontSize: "large",
                          fontWeight: "bold",
                          bgcolor: "#f1f1f1",
                          textAlign: "center",
                          position: "sticky",
                          top: 0, // Stick the header to the top
                          zIndex: 1, // Ensure the header stays on top during scroll
                        }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:nth-of-type(even)": { bgcolor: "#fafafa" },
                        "&:hover": { bgcolor: "#f0f0f0" },
                      }}
                    >
                      {previewColumns.map((column) => (
                        <TableCell key={column} sx={{ textAlign: "center" }}>
                          {row[column]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            borderRadius: 2,
            overflow: "hidden",
            boxShadow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              bgcolor: theme.palette.primary.dark,
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 20px 0 20px",
              position: "relative", // Ensures children are positioned correctly
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  p: 2,
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Processed Data
              </Typography>
            </Box>
            {resultData.length > 0 && uploadStatus === "completed" && (
              <Button
                variant="contained"
                color="secondary"
                onClick={exportToExcel}
                sx={{
                  bgcolor: "#4caf50",
                  color: "#fff",
                  "&:hover": { bgcolor: "#388e3c" },
                  position: "absolute",
                  right: 20,
                }}
              >
                Download Report
              </Button>
            )}
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              height: "650px", // Adjust height as needed
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {resultColumns.map((column) => (
                    <TableCell
                      key={column}
                      sx={{
                        fontSize: "large",
                        fontWeight: "bold",
                        bgcolor: "#f1f1f1",
                        minWidth: "150px",
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {toHumanReadableFormat(column)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { bgcolor: "#fafafa" },
                      "&:hover": { bgcolor: "#f0f0f0" },
                    }}
                  >
                    {resultColumns.map((column) => (
                      <TableCell
                        key={column}
                        sx={{ minWidth: "150px", textAlign: "center" }}
                      >
                        {row[column] || "N/A"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="upload-complete-dialog"
        aria-describedby="upload-complete-dialog-description"
      >
        <DialogTitle id="upload-complete-dialog">Process Complete</DialogTitle>
        <DialogContent>
          <Typography
            id="upload-complete-dialog-description"
            sx={{ minWidth: "400px" }}
          >
            You can download the report by clicking on{" "}
            <strong>Download Report</strong> at the top right corner of the
            Processed Data Table.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VehicleRCValidation;
