export const serviceTemplates = {
  outdoor: [
    "state",
    "city",
    "type",
    "pincode",
    "area",
    "location",
    "towards",
    "width",
    "height",
    "areaInSqFeet",
    "displayCostPerMonth",
    "longitude",
    "latitude",
    "landmark",
    "vendorId",
    "vendorAssetCode",
    "vendorName",
    "remarks",
  ],
  digital: [
    "mediaType",
    "zone",
    "locality",
    "propertyName",
    "pinCode",
    "propertyPriceUpto",
    "screenSize",
    "noOfTowers",
    "noOfScreens",
    "captiveHouseholds",
    "approxReach",
    "noOfImpressionsPerMonth",
    "locationMapLink",
    "latitude",
    "longitude",
    "facilityType",
    "tenantDetails",
    "nccsClass",
    "mediaSiteId",
  ],
};
