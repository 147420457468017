import {
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { resendEmailVerification } from "../clients/resendEmailVerification";
import { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";

const GoToEmailInbox = () => {
  const location = useLocation();
  const { emailId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  const identifyEmailBrand = (email: string) => {
    const domain = email.split("@")[1];
    if (domain === "gmail.com") {
      return "Gmail";
    } else if (domain === "outlook.com") {
      return "Outlook";
    } else {
      return null;
    }
  };
  const emailBrand = identifyEmailBrand(emailId);
  const handleOpenInbox = () => {
    // Logic to open the respective email inbox
    if (emailBrand === "Gmail") {
      window.open("https://mail.google.com", "_blank"); // Replace with Gmail's inbox URL
    } else if (emailBrand === "Outlook") {
      window.open("https://outlook.live.com", "_blank"); // Replace with Outlook's inbox URL
    }
  };

  const handleSnackbarClose = () => {
    setSuccessMessage(""); // Close Snackbar by clearing the success message
  };

  const [successMessage, setSuccessMessage] = useState("");

  const handleResendVerificationMail = async () => {
    if (buttonDisabled) return;
    setButtonDisabled(true);
    setTimer(30);
    setLoading(true);
    try {
      await resendEmailVerification(emailId);
      setSuccessMessage("Verification mail sent!"); // Set success message on successful email sending
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timer === 0) {
      setButtonDisabled(false);
    }
  }, [timer]);

  return (
    <Grid container justifyContent="center" sx={{ marginTop: "15px" }}>
      <Grid item xs={12} sm={8} md={6}>
        <Box mt={3}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Verify your email to continue
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              mt: 2,
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            We just sent an email to the address:{" "}
            <span style={{ fontWeight: "500" }}>{emailId}</span>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              mt: 2,
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            Please check your email and select the link provided to verify your
            address.
          </Typography>
        </Box>
        {successMessage && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }} // Set the position to top center
            onClose={handleSnackbarClose}
          >
            <MuiAlert onClose={handleSnackbarClose} severity="success">
              {successMessage}
            </MuiAlert>
          </Snackbar>
        )}
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
          sx={{ margin: "15px" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Button
            onClick={handleResendVerificationMail}
            variant="contained"
            disabled={buttonDisabled}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={24} />
            ) : (
              "Resend verification Email "
            )}
          </Button>
          {emailBrand !== null && (
            <Button
              onClick={handleOpenInbox}
              variant="contained"
            >
              Go to {emailBrand} Inbox
            </Button>
          )}
        </Box>
        {buttonDisabled && (
          <Typography
            variant="body1"
            color="#e57373"
            sx={{ textAlign: "center", mt: 2 }}
          >
            Please wait for <span style={{ color: "#1976d2" }}>00:{timer}</span>{" "}
            seconds before resending the email.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default GoToEmailInbox;
