export const CinemaMediaMapper = (columns: string[]): string[] => {
    const mappings: { [key: string]: string } = {
        region: "region",
        state: "state",
        city: "city",
        cinemaCategory: "cinemaCategory",
        cinema: "cinema",
        audi: "audi",
        seatingCapacity: "seatingCapacity",
        screenCode: "screenCode",
        sapCode: "sapCode",
        sapAudiCode: "sapAudiCode",
        audiForm: "audiForm"
    };

    return columns.map((column) => mappings[column] || column);
};
