import React, { useState } from "react";
import { styled } from "@mui/system";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import {
  Box,
  Button,
  Typography,
  TextField,
  CardContent,
  Card,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";

interface ImageData {
  image: File | null;
  tags: string;
  location: string;
  additionalInfo: string;
}

const DragAndDropArea = styled("div")(() => ({
  border: "2px dashed orange",
  borderRadius: "8px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "320px",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    "& p": {
      color: "orange",
      fontWeight: "bold",
    },
  },
}));

const StyledCard = styled(Card)(() => ({
  maxWidth: "600px",
  margin: "0 auto",
  marginTop: "50px",
  padding: "20px",
  border: "1px solid orange",
}));

const PreviewImage = styled("img")(() => ({
  maxWidth: "100%",
  maxHeight: "80%",
}));

const RemoveButton = styled(Button)({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  height: "10%",
  bottom: "8px",
});

const FileUpload: React.FC = () => {
  const [imageData, setImageData] = useState<ImageData>({
    image: null,
    tags: "",
    location: "",
    additionalInfo: "",
  });
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const mediaData = location.state?.modalData;
  const mediaDataPath: string = location.state?.location;

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        setImageData({ ...imageData, image: compressedFile });
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        setImageData({ ...imageData, image: compressedFile });
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setImageData({ ...imageData, [name]: value });
  };

  const handleRemoveImage = () => {
    setImageData({ ...imageData, image: null });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      if (!imageData.image) {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Please select an image");
        }, 100);
        return;
      } else formData.append("image", imageData.image);

      const metadata = {
        location: imageData.location,
        additionalInfo: imageData.additionalInfo,
      };

      const additionalFileData = {
        tags: imageData.tags,
        metadata,
      };
      formData.append("additionalFileData", JSON.stringify(additionalFileData));

      const response: any = await uploadFileWithMetadata(formData);

      if (response.ok) {
        handleSnackbar("success", "Image uploaded successfully!");
        setImageData({
          image: null,
          tags: "",
          location: "",
          additionalInfo: "",
        });
        if (mediaData && location.pathname !== mediaDataPath) {
          setTimeout(() => {
            navigate(mediaDataPath, { state: { mediaData } });
          }, 1000);
        }
      } else {
        handleSnackbar("error", "Failed to Upload Image");
        console.error("Failed to upload image.");
      }
    } catch (error) {
      handleSnackbar("error", "Error Uploading Image");
      console.error("Error uploading image:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Upload Image
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <DragAndDropArea
              onDragOver={handleDragOver}
              onDrop={imageData.image ? undefined : handleDrop}
              onClick={
                imageData.image
                  ? undefined
                  : () => document.getElementById("image")?.click()
              }
              style={{ cursor: imageData.image ? "not-allowed" : "pointer" }}
            >
              {imageData.image ? (
                <>
                  <PreviewImage
                    src={URL.createObjectURL(imageData.image)}
                    alt="Preview"
                  />
                  <RemoveButton onClick={handleRemoveImage}>
                    Remove
                  </RemoveButton>
                </>
              ) : (
                <Typography>
                  Drag & Drop file here, or click to select
                </Typography>
              )}
            </DragAndDropArea>
            <input
              type="file"
              id="image"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Tags"
              name="tags"
              value={imageData.tags}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>
          <TextField
            label="Location"
            name="location"
            value={imageData.location}
            onChange={handleInputChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Additional Info"
            name="additionalInfo"
            value={imageData.additionalInfo}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <Box display="flex" justifyContent="center" mt={2}>
            <Button variant="contained" type="submit">
              Upload
            </Button>
          </Box>
        </form>
      </CardContent>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </StyledCard>
  );
};

export default FileUpload;
