import { FormControl, Button, FormHelperText, Box } from "@mui/material";
import { DeleteForeverOutlined as DeleteIcon } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { Vendors } from "./vendorTypes";
import FilePreviewModal, { filePreviewUrl } from "./FilePreviewModal";

interface VendorFileInputProps {
  label: string;
  name: keyof Vendors;
  value: string | File | null;
  disable?: boolean;
  required?: boolean;
  onFileInputChange: (name: string, value: File | null) => void;
}

export const VendorFileInput = ({
  label,
  name,
  disable,
  value,
  required,
  onFileInputChange,
}: VendorFileInputProps) => {
  const [file, setFile] = useState<File | string | null>(null);
  const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false);
  const [fileType, setFileType] = useState<string | null>(null);

  useEffect(() => {
    const determineFileType = async (url: string) => {
      try {
        const response = await fetch(url, { method: "GET" });
        const contentType = response.headers.get("content-type");
        if (contentType) setFileType(contentType);
      } catch (error) {
        console.error("Error determining file type:", error);
      }
    };

    if (value) {
      setFile(value);
      value instanceof File
        ? setFileType(value.type)
        : determineFileType(value);
    }
  }, [value]);

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileInput = e.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length > 0) {
        const selectedFile = fileInput.files[0];
        console.log(selectedFile.type);
        const validTypes = [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "image/jpg",
        ];
        if (validTypes.includes(selectedFile.type)) {
          setFile(selectedFile);
          setOpenFilePreviewModal(true);
        } else {
          alert("Please upload PDF or Image file.");
        }
      }
      e.target.value = "";
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setOpenFilePreviewModal(false);
  }, []);

  const handleFileInput = useCallback(
    (name: string, value: File | null) => {
      onFileInputChange(name, value);
      setFile(value);
      handleCloseModal();
    },
    [handleCloseModal, onFileInputChange]
  );

  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      fullWidth
    >
      <Box>
        <Button          
          variant="outlined"
          component="label"
          disabled={value ? true : disable}
          fullWidth
        >
          {label}
          <input
            type="file"
            name={name}
            hidden
            accept="image/jpeg, image/png, application/pdf, image/jpg"
            onChange={handleFileInputChange}
            required={required}
          />
        </Button>
        {value ? (
          <FormHelperText>
            Remove the attached image to select another
          </FormHelperText>
        ) : (
          <FormHelperText>
            Max size : 5 MB Supported File Types : .png,
            .jpeg,.jpg,.pdf
          </FormHelperText>
        )}
      </Box>
      {file && fileType && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
          {fileType.startsWith("image/") && (
            <img
              src={filePreviewUrl(file) ?? undefined}
              alt=""
              onClick={() => {
                setFile(file);
                setOpenFilePreviewModal(true);
              }}
              style={{ maxWidth: "120px", maxHeight: "60px" }}
            />
          )}
          {fileType === "application/pdf" && (
            <div
              onClick={() => {
                setFile(file);
                setOpenFilePreviewModal(true);
              }}
              style={{ display: "inline-block", cursor: "pointer" }}
            >
              <embed
                src={filePreviewUrl(file) ?? undefined}
                type="application/pdf"
                style={{
                  maxWidth: "120px",
                  maxHeight: "60px",
                  pointerEvents: "none",
                }}
              />
            </div>
          )}
          <DeleteIcon
            fontSize="large"
            color="error"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setFile(null);
              handleFileInput(name, null);
            }}
          />
        </Box>
      )}
      <FilePreviewModal
        open={openFilePreviewModal}
        fieldName={name}
        fileData={file}
        close={handleCloseModal}
        handleFileInput={handleFileInput}
      />
    </FormControl>
  );
};
