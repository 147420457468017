import { AuthApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function getUser(authHeader: string) {
    
    const headers = {
        Authorization: authHeader,
    };
    
    return fetchWrapper(`${AuthApiBaseUrl}/getUser`, {
        method: 'GET',
        headers: headers,
    });
}
