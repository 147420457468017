import { GingerMediaBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export default async function getVendorDetails(vendorId?: string) {
    if (vendorId) {
        return fetchWrapper(`${GingerMediaBaseUrl}/getVendorDetails?vendorId=${vendorId}`,
            {
                method: 'GET',
            }
        );
    }
    return fetchWrapper(`${GingerMediaBaseUrl}/getVendorDetails`,
        {
            method: 'GET',
        }
    );
}