import { AuthApiBaseUrl, clientId } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function getAnonymousToken(): Promise<{ token: string }> {
  const res = await fetchWrapper(
    `${AuthApiBaseUrl}/getAnonymousToken?clientId=${clientId}`,
    {
      method: "GET",
    }
  );
  return res.data as { token: string };
}
