import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

const VendorsMenu: React.FC = () => {
  const navigate = useNavigate();

  const handleVendorsList = () => {
    navigate("/vendors/vendorsList");
  };

  const handleVendorRegistation = () => {
    navigate("/vendors/registerVendor");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100vh - 90px)`,
        gap: "20px",
      }}
    >
      <Button
        size="large"
        variant="contained"
        startIcon={<RecentActorsIcon />}
        onClick={handleVendorsList}
      >
        Vendors List
      </Button>
      <Button
        size="large"
        variant="contained"
        startIcon={<PersonAddIcon />}
        onClick={handleVendorRegistation}
      >
        Register Vendor
      </Button>
    </Box>
  );
};

export default VendorsMenu;
