import { Outlet } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import UnauthorizedPageAccess from "./home/UnauthorizedPageAccess";

const InternalRoutes = () => {
  const { userDetails } = useAuthContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userDetails !== null) {
      setLoading(false);
    }
  }, [userDetails]);

  const hasAccess =
    userDetails?.role === "gogig-role-o1" ||
    userDetails?.role === "gogig-role-a1";

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <CircularProgress />
    </Box>
  ) : hasAccess ? (
    <Box>
      <Outlet />
    </Box>
  ) : (
    <UnauthorizedPageAccess />
  );
};

export default InternalRoutes;
