import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { Vendors } from "./vendorTypes";

interface FilePreviewModalProps {
  open: boolean;
  fileData: File | string | null;
  fieldName: keyof Vendors;
  close: () => void;
  handleFileInput: (name: string, value: File | null) => void;
}

export const filePreviewUrl = (fileData: File | string | null) => {
  if (fileData instanceof File) {
    return URL.createObjectURL(fileData);
  }
  return typeof fileData === "string" ? fileData : null;
};

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({
  open,
  fileData,
  fieldName,
  close,
  handleFileInput,
}) => {
  const [error, setError] = useState("");
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  useEffect(() => {
    const determineFileType = async (url: string) => {
      try {
        const response = await fetch(url, { method: "GET" });
        const contentType = response.headers.get("content-type");
        setFileType(contentType);
      } catch (error) {
        console.error("Error determining file type:", error);
      }
    };

    if (fileData) {
      if (fileData instanceof File) {
        setFileUrl(filePreviewUrl(fileData));
        setFileType(fileData.type);
      } else if (typeof fileData === "string") {
        setFileUrl(fileData);
        determineFileType(fileData);
      }
    }
  }, [fileData]);

  useEffect(() => {
    if (fileData instanceof File && fileData.size > 5 * 1024 * 1024) {
      setError("File size exceeds 5MB");
    } else {
      setError("");
    }
  }, [fileData]);

  return (
    <Modal open={open} onClose={close}>
      <Box
        p={2}
        bgcolor="white"
        maxWidth="1200px"
        maxHeight="900px"
        height="800px"
        margin="auto"
        border="1px solid "
        mt={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ marginLeft: 3 }}>
          File Preview
        </Typography>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          {fileUrl ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                textAlign: "center",
              }}
            >
              {fileType?.startsWith("image/") && (
                <img
                  src={fileUrl}
                  alt="Preview"
                  style={{ maxHeight: "650px", maxWidth: "1100px" }}
                />
              )}
              {fileType === "application/pdf" && (
                <embed
                  src={fileUrl}
                  type="application/pdf"
                  width="1100px"
                  height="650px"
                />
              )}
              {!(
                fileType?.startsWith("image/") || fileType === "application/pdf"
              ) && (
                <Typography variant="body1">
                  File preview not available for this file type.
                </Typography>
              )}
              {error && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "20px",
            gap: 50,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            onClick={() => {
              handleFileInput(fieldName, null);
            }}
          >
            Discard
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              if (fileData instanceof File)
                handleFileInput(fieldName, fileData);
            }}
            disabled={!!error}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilePreviewModal;
