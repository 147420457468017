import { fetchWrapper } from "./fetchWrapper";
import { GingerMediaBaseUrl } from "./baseUrls";

export async function createMediaDetails(service: string, medium: string, formValues: any, authHeader: string) {
    return fetchWrapper(`${GingerMediaBaseUrl}/createMediaDetails?service=${service}&medium=${medium}`, {
        method: 'POST',
        headers: {
            Authorization: authHeader,
        },
        body: formValues
    });
}