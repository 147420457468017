import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  Modal,
  Link,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import getVendorDetails from "../../clients/getVendorDetails";
import { useNavigate } from "react-router-dom";
import { deleteVendorDetails } from "../../clients/deleteVendorDetails";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { Delete, Edit, CopyAll as CopyAllIcon } from "@mui/icons-material";

interface VendorDetails {
  vendorId: string;
  gstNumber: string | null;
  panNumber: string;
  vendorEmail: string;
  registrationType: string;
  firmName: string;
  registrationComplete: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  maxWidth: "md",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VendorList: React.FC = () => {
  const [vendors, setVendors] = useState<VendorDetails[]>([]);
  const [filteredVendors, setFilteredVendors] = useState<VendorDetails[]>([]);
  const [selectedVendors, setSelectedVendors] = useState<Set<string>>(
    new Set()
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuVendorId, setMenuVendorId] = useState<string | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState<string | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await getVendorDetails();
      if (response.data) {
        const vendorDetails = response.data.vendorDetails || [];
        setVendors(vendorDetails);
        setFilteredVendors(vendorDetails);
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, confirmDelete]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedVendors(new Set(vendors.map((v) => v.vendorId)));
    } else {
      setSelectedVendors(new Set());
    }
  };

  const handleSelect = (vendorId: string) => {
    const newSelectedVendors = new Set(selectedVendors);
    if (selectedVendors.has(vendorId)) {
      newSelectedVendors.delete(vendorId);
    } else {
      newSelectedVendors.add(vendorId);
    }
    setSelectedVendors(newSelectedVendors);
  };

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    vendorId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuVendorId(vendorId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuVendorId(null);
  };

  const handleEdit = (vendorId: string) => {
    navigate(`/vendors/registerVendor/${vendorId}`);
    handleCloseMenu();
  };

  const handleDelete = (vendorId: string) => {
    setVendorToDelete(vendorId);
    setConfirmDelete(true);
    handleCloseMenu();
  };

  const confirmDeleteVendor = async () => {
    try {
      if (vendorToDelete) {
        await deleteVendorDetails(vendorToDelete);
        setVendors((prev) =>
          prev.filter((vendor) => vendor.vendorId !== vendorToDelete)
        );
        setConfirmDelete(false);
        setVendorToDelete(null);
        handleSnackbar("info", `${vendorToDelete} has been deleted`);
      } else {
        handleSnackbar("error", `Error deleting vendor`);
      }
    } catch (error) {
      console.error("Error deleting vendor:", error);
    }
  };

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleSnackbar = useCallback((severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchTerm(searchText);
    const filtered = vendors.filter((vendor) =>
      vendor.panNumber.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredVendors(filtered);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      handleSnackbar("info", "Copied to clipboard!");
    });
  };

  return (
    <Box sx={{ marginRight: 15, marginLeft: 15 }}>
      <Box sx={{ display: "flex", alignItems: "center", margin: "10px 20px" }}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          Registered Vendors
        </Typography>
        <TextField
          label="Search by PAN Number"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="75vh"
        >
          <CircularProgress size="60px" />
        </Box>
      ) : (
        <>
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    sx={{ color: "" }}
                    indeterminate={
                      selectedVendors.size > 0 &&
                      selectedVendors.size < vendors.length
                    }
                    checked={
                      vendors.length > 0 &&
                      selectedVendors.size === vendors.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    width: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Vendor ID
                </TableCell>
                <TableCell
                  sx={{
                    width: "150px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  GST Number
                </TableCell>
                <TableCell
                  sx={{
                    width: "150px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  PAN Number
                </TableCell>
                <TableCell
                  sx={{
                    width: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Email Address
                </TableCell>
                <TableCell
                  sx={{
                    width: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Firm Name
                </TableCell>
                <TableCell
                  sx={{
                    width: "150px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    width: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVendors.slice(0, 50).map((vendor) => (
                <TableRow key={vendor.vendorId}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      sx={{ color: "" }}
                      checked={selectedVendors.has(vendor.vendorId)}
                      onChange={() => handleSelect(vendor.vendorId)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "100px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Link
                      component="button"
                      sx={{
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        navigate(`/vendors/vendorProfile/${vendor.vendorId}`);
                      }}
                    >
                      {vendor.vendorId}
                    </Link>
                    <Tooltip title="Copy to clipboard">
                      <IconButton
                        onClick={() => copyToClipboard(vendor.vendorId)}
                      >
                        <CopyAllIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {vendor.gstNumber ? vendor.gstNumber : "N/A"}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {vendor.panNumber}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {vendor.vendorEmail}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {vendor.firmName}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {vendor.registrationComplete === 0
                      ? "Incomplete"
                      : "Complete"}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <IconButton
                      onClick={(e) => handleOpenMenu(e, vendor.vendorId)}
                    >
                      <MoreVertIcon sx={{ color: "" }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={
                        Boolean(anchorEl) && menuVendorId === vendor.vendorId
                      }
                      onClose={handleCloseMenu}
                    >
                      <MenuItem
                        onClick={() => handleEdit(vendor.vendorId)}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        Edit
                        <Edit fontSize="small" color="primary" />
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDelete(vendor.vendorId)}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "10px",
                        }}
                      >
                        Delete
                        <Delete fontSize="small" color="primary" />
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Modal
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby="confirm-delete-title"
            aria-describedby="confirm-delete-description"
          >
            <Box sx={style}>
              <Typography id="confirm-delete-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <Typography id="confirm-delete-description" sx={{ mt: 2 }}>
                Are you sure you want to delete this vendor?
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  gap: 20,
                }}
              >
                <Button
                  onClick={() => setConfirmDelete(false)}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button onClick={confirmDeleteVendor} variant="contained">
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default VendorList;
