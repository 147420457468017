import React, { useState, useEffect, useCallback } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import getVendorDetails from "../../clients/getVendorDetails";
import { Vendors } from "./vendorTypes";
import {
  RenderFileField,
  RenderTextField,
  Section,
} from "./VendorProfileRender";

const VendorProfile: React.FC = () => {
  const [vendorData, setVendorData] = useState<Vendors | null>(null);
  const { vendorId } = useParams<{ vendorId?: string }>();

  const fetchVendorData = useCallback(async () => {
    try {
      const response: any = await getVendorDetails(vendorId);
      setVendorData(response.data.vendorDetails);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorId) {
      fetchVendorData();
    }
  }, [vendorId, fetchVendorData]);

  if (!vendorData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        Vendor Profile
      </Typography>
      <Section title="Tax and Compliance">
        <Grid container spacing={2}>
          <RenderTextField label="Vendor ID" value={vendorData.vendorId} />
          <RenderTextField label="GST Number" value={vendorData.gstNumber} />
          <RenderTextField
            label="Tax Section 206AB/206CCA"
            value={vendorData.taxSection206AB206CCA ? "Yes" : "No"}
          />
          <RenderFileField
            label="GST Certificate Copy"
            file={vendorData.gstCertCopy}
          />
          <RenderTextField
            label="GST Available"
            value={vendorData.isGstAvailable ? "Yes" : "No"}
          />
          <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}></Grid>
          <RenderTextField label="PAN Number" value={vendorData.panNumber} />
          <RenderFileField label="PAN Copy" file={vendorData.panCopy} />
          <RenderTextField label="TAN Number" value={vendorData.tanNumber} />
          <RenderFileField label="TAN Copy" file={vendorData.tanCopy} />
          <RenderTextField
            label="Registered with MSME"
            value={vendorData.isRegisteredWithMSME ? "Yes" : "No"}
          />
          <RenderTextField label="MSME Number" value={vendorData.msmeNumber} />
          <RenderFileField
            label="MSME Certificate"
            file={vendorData.msmeCertCopy}
          />
          <RenderTextField
            label="Lower TDS Certificate"
            value={vendorData.lowerTdsCert ? "Yes" : "No"}
          />
          <RenderFileField
            label="Lower TDS Certificate Copy"
            file={vendorData.lowerTdsCertCopy}
          />
          <RenderTextField label="SAC/HSN" value={vendorData.sacHsn} />
          <RenderFileField
            label="Tax Declaration 206AB"
            file={vendorData.taxDeclaration206AB}
          />
        </Grid>
      </Section>
      <Section title="General Information">
        <Grid container spacing={2}>
          <RenderTextField
            label="Email Address"
            value={vendorData.vendorEmail}
          />
          <RenderTextField
            label="Registration Type"
            value={vendorData.registrationType}
          />
          <RenderTextField label="Firm Name" value={vendorData.firmName} />
          <RenderTextField
            label="Organization Type"
            value={vendorData.organizationType}
          />
          <RenderTextField
            label="Incorporation Date"
            value={new Date(vendorData.incorporationDate).toLocaleDateString()}
          />
          <RenderFileField
            label="Incorporation Certificate"
            file={vendorData.incorporationCertCopy}
          />
          <RenderTextField
            label="Proprietor Name"
            value={vendorData.proprietorName}
          />
          <RenderTextField
            label="Registered Address"
            value={vendorData.registeredAddress}
          />
          <RenderTextField
            label="Billing Address"
            value={vendorData.billingAddress}
          />
          <RenderTextField
            label="Shipping Address"
            value={vendorData.shippingAddress}
          />
          <RenderTextField
            label="Business Nature"
            value={vendorData.businessNature}
          />
        </Grid>
      </Section>
      <Section title="Business and Finance">
        <Grid container spacing={2}>
          <RenderTextField
            label="Business Contact Name"
            value={vendorData.businessContactName}
          />
          <RenderTextField
            label="Business Designation"
            value={vendorData.businessDesignation}
          />
          <RenderTextField
            label="Business Contact Number"
            value={vendorData.businessContactNumber}
          />
          <RenderTextField
            label="Business Telephone"
            value={vendorData.businessTelephone}
          />
          <RenderTextField
            label="Business Email"
            value={vendorData.businessEmail}
          />
          <RenderTextField
            label="Finance Contact Name"
            value={vendorData.financeContactName}
          />
          <RenderTextField
            label="Finance Designation"
            value={vendorData.financeDesignation}
          />
          <RenderTextField
            label="Finance Contact Number"
            value={vendorData.financeContactNumber}
          />
          <RenderTextField
            label="Finance Email"
            value={vendorData.financeEmail}
          />
        </Grid>
      </Section>
      <Section title="Bank Information">
        <Grid container spacing={2}>
          <RenderTextField
            label="Account Number"
            value={vendorData.accountNumber}
          />
          <RenderTextField
            label="Confirm Account Number"
            value={vendorData.confirmAccountNumber}
          />
          <RenderTextField
            label="Account Holder Name"
            value={vendorData.accountHolderName}
          />
          <RenderTextField label="IFSC Code" value={vendorData.ifscCode} />
          <RenderTextField label="Bank Name" value={vendorData.bankName} />
          <RenderTextField
            label="Bank Address"
            value={vendorData.bankAddress}
          />
          <RenderFileField
            label="Cancelled Cheque Copy"
            file={vendorData.cancelledChequeCopy}
          />
        </Grid>
      </Section>
      <Section title="OtherDocuments">
        <Grid container spacing={2}>
          <RenderFileField
            label="Other OtherDocuments"
            file={vendorData.otherOtherDocuments}
          />
          <RenderFileField
            label="Latest Financials"
            file={vendorData.latestFinancials}
          />
          <RenderFileField
            label="Publisher Edit URL"
            file={vendorData.formPublisherEditUrl}
          />
          <RenderFileField
            label="Publisher Document URL"
            file={vendorData.formPublisherDocUrl}
          />
          <RenderFileField
            label="Publisher PDF URL"
            file={vendorData.formPublisherPdfUrl}
          />
        </Grid>
      </Section>
    </Container>
  );
};

export default VendorProfile;
