import { fetchWrapper } from "./fetchWrapper";
import { AuthApiBaseUrl } from "./baseUrls";
  
export async function resendEmailVerification( emailId: string ) {
  const requestBody = {
        emailId,
    };
  return fetchWrapper(`${AuthApiBaseUrl}/resendVerificationEmail`, {
    method: 'PATCH',
    body: requestBody,
  });
}
  