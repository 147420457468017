import { CheckCircle, Cancel } from "@mui/icons-material";
import { Box, CircularProgress, Typography, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
import { useState } from "react";

interface BulkMediaUploadPreviewProps {
  filePreview: string[][] | null;
  uploadInitiated: boolean;
  rowStatus: string[];
  pptxImages: File[];
}

const OverscrollContainBox = styled(Box)({
  border: "1px solid ",
  borderRadius: "5px",
  padding: "10px",
  maxHeight: "530px",
  overflow: "auto",
  margin: "20px auto",
  maxWidth: "90%",
  overscrollBehaviorX: "contain",
  "&::-webkit-scrollbar": {
    width: "10px",
    height: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1976d2",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#1976d2",
  },
});

const BulkMediaUploadPreview = ({
  filePreview,
  uploadInitiated,
  rowStatus,
  pptxImages,
}: BulkMediaUploadPreviewProps) => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const filteredRows = () => {
    return filePreview
      ? filePreview.filter((_, index) => {
          if (index === 0) return true;
          if (tabValue === 0) return true;
          const rowStatusValue = rowStatus[index - 1];
          return (
            (tabValue === 1 && rowStatusValue === "success") ||
            (tabValue === 2 && rowStatusValue === "failed")
          );
        })
      : [];
  };

  const rows = filteredRows();

  return (
    <OverscrollContainBox>
      <Tabs value={tabValue} onChange={handleChange} aria-label="status tabs">
        <Tab label="All" />
        <Tab label="Success" />
        <Tab label="Failed" />
      </Tabs>
      {filePreview &&
        (rows.length === 1 ? (
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            No Rows To Display
          </Typography>
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#1976d2", color: "" }}>
                <th
                  style={{
                    padding: "5px",
                    border: "1px solid #000000",
                    minWidth: "50px",
                  }}
                >
                  #
                </th>
                <th
                  style={{
                    padding: "5px",
                    border: "1px solid #000000",
                    minWidth: "150px",
                  }}
                >
                  Upload Status
                </th>
                {filePreview[0].map((cell, cellIndex) => (
                  <th
                    key={cellIndex}
                    style={{
                      padding: "5px",
                      border: "1px solid #000000",
                      minWidth: "150px",
                    }}
                  >
                    {cell !== null && cell !== undefined && cell !== ""
                      ? cell
                      : "\u00A0"}
                  </th>
                ))}
                {pptxImages.length > 0 && (
                  <th
                    style={{
                      padding: "5px",
                      border: "1px solid #000000",
                      minWidth: "150px",
                    }}
                  >
                    Image
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredRows().map((row, rowIndex) =>
                rowIndex > 0 ? (
                  <tr
                    key={rowIndex}
                    style={{
                      backgroundColor: "inherit",
                      color: "inherit",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px",
                        border: "1px solid #000000",
                        minWidth: "50px",
                      }}
                    >
                      {rowIndex}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        border: "1px solid #000000",
                        minWidth: "150px",
                      }}
                    >
                      {uploadInitiated ? (
                        <>
                          {rowStatus[rowIndex - 1] === "uploading" && (
                            <CircularProgress
                              size={24}
                              style={{ color: "green" }}
                            />
                          )}
                          {rowStatus[rowIndex - 1] === "success" && (
                            <CheckCircle
                              style={{ color: "green", fontSize: 24 }}
                            />
                          )}
                          {rowStatus[rowIndex - 1] === "failed" && (
                            <Cancel style={{ color: "red", fontSize: 24 }} />
                          )}
                        </>
                      ) : (
                        <Typography>Not Initiated</Typography>
                      )}
                    </td>
                    {row.map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          padding: "5px",
                          border: "1px solid #000000",
                          minWidth: "150px",
                        }}
                      >
                        {cell !== null && cell !== undefined && cell !== ""
                          ? cell
                          : "\u00A0"}
                      </td>
                    ))}
                    {pptxImages[rowIndex - 1] && (
                      <td
                        style={{
                          padding: "5px",
                          border: "1px solid #000000",
                          minWidth: "150px",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(pptxImages[rowIndex - 1])}
                          alt={`Slide ${rowIndex}`}
                          style={{ width: "100px" }}
                        />
                      </td>
                    )}
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        ))}
    </OverscrollContainBox>
  );
};

export default BulkMediaUploadPreview;
