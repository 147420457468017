import { Box, Container, Typography, Button } from "@mui/material";
import React from "react";
import { useAuthContext } from "../providers/AuthProvider";

interface LogoTextProps {
  logoName: string;
  text: string;
}

const SuccessSubmission: React.FC<LogoTextProps> = ({ logoName, text }) => {
  const { logout } = useAuthContext();
  const redirectToLoginPage = () => {
    logout({ returnTo: "/login" });
  };
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "400px",
          border: "2px solid #eee ",
          borderRadius: "10px",
          padding: "25px",
          marginTop: "25px",
        }}
      >
        <Typography
          sx={{ display: "flex", textAlign: "center", marginBottom: "15px" }}
        >
          {text}
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
        >
          <Button sx={{ textTransform: "none" }} onClick={redirectToLoginPage}>
            Go back to Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SuccessSubmission;
