import { AuthApiBaseUrl, clientId } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function resetPassword(emailId: string) {
    const url = `${AuthApiBaseUrl}/resetPassword?clientId=${clientId}`;
    return fetchWrapper( url,{
        method: 'POST',
        body: {emailId},
    });
}
