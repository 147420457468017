import { TableData } from "./VehicleRCValidation";

export const toHumanReadableFormat = (input: string): string => {
    return input
        .split('_') // Split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with a space
}

export const convertArrayToHumanReadable = (array: string[]): string[] => {
    return array.map(toHumanReadableFormat);
}

export const modifyTableDataKeys = (
    data: TableData[],
): TableData[] => {
    return data.map((item) =>
        Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
                toHumanReadableFormat(key),
                value,
            ])
        )
    );
}

export const rearrangeData = (data: TableData[], columns: string[]) => {
    return data.map(row => {
        const newRow: TableData = {};
        columns.forEach(column => {
            newRow[column] = row[column] || "N/A"; // Default value if data is missing
        });
        return newRow;
    });
};

export const desiredColumnOrder = [
    "RC Number",
    "color",
    "maker_model",
    "manufacturer",
    "owner_name",
    "vehicle_class"
]