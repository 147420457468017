import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { VendorBankDetails, Vendors } from "./vendorTypes";
import getVendorDetails from "../../clients/getVendorDetails";
import { updateVendorDetails } from "../../clients/updateVendorDetails";
import {
  bankDetailsDefaultValues,
  fileFields,
  requiredFields,
  steps,
} from "./vendorConstants";
import { isIFSCValid, validateField } from "./vendorValidators";
import { VendorFileInput } from "./VendorFileInput";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import useHandleEmptyStringValues from "./CustomHook";
import { useAuthContext } from "../../providers/AuthProvider";

interface BankDetailsProps {
  activeStep: number;
  handleStepChange: (step: number) => Promise<void>;
  vendorId?: string;
}

const BankDetails = ({
  activeStep,
  handleStepChange,
  vendorId,
}: BankDetailsProps) => {
  const { authHeader } = useAuthContext();
  const [bankDetailsValues, setBankDetailsValues] = useState<VendorBankDetails>(
    bankDetailsDefaultValues
  );
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasFetchedData = useRef(false);

  const HandleEmptyStringValues = () =>
    useHandleEmptyStringValues({
      values: bankDetailsValues,
      setValue: setBankDetailsValues,
    });

  const handleSnackbar = useCallback((severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const fetchFile = useCallback(async (url: string, field: string) => {
    try {
      const fileResponse = await fetch(url, { method: "GET" });
      if (!fileResponse.ok) {
        throw new Error(`File for ${field} could not be fetched`);
      }
      const arrayBuffer = await fileResponse.arrayBuffer();
      const contentType = fileResponse.headers.get("content-type") || undefined;
      return new File([arrayBuffer], field, { type: contentType });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  }, []);

  const fetchBasicInformationDetails = useCallback(
    async (vendorId: string) => {
      setLoading(true);
      try {
        const response: any = await getVendorDetails(vendorId);
        const vendorDetails: Vendors = response.data.vendorDetails;
        const bankDetailsKeys = Object.keys(bankDetailsDefaultValues);

        Object.keys(vendorDetails).forEach((key) => {
          if (!bankDetailsKeys.includes(key as keyof VendorBankDetails))
            delete vendorDetails[key as keyof Vendors];
        });
        const bankDetailsData: VendorBankDetails =
          vendorDetails as VendorBankDetails;

        const bankDetailsFields: (keyof VendorBankDetails)[] =
          fileFields.filter((field) =>
            bankDetailsKeys.includes(field as keyof VendorBankDetails)
          ) as (keyof VendorBankDetails)[];

        for (const field of bankDetailsFields) {
          const fieldValue = bankDetailsData[field];
          if (fieldValue) {
            const file = await fetchFile(String(fieldValue), field);
            if (file) {
              (bankDetailsData[field] as File | string | null) = file;
            }
          }
        }

        setBankDetailsValues(bankDetailsData);
      } catch (error) {
        console.error("Error in fetching basic information : ", error);
        handleSnackbar("error", "Error in fetching basic information");
      } finally {
        setLoading(false);
      }
    },
    [fetchFile, handleSnackbar]
  );

  useEffect(() => {
    if (vendorId && !hasFetchedData.current) {
      fetchBasicInformationDetails(vendorId);
      hasFetchedData.current = true;
    }
  }, [vendorId, fetchBasicInformationDetails]);

  const handleTextInputChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      uppercase?: boolean
    ) => {
      const { name, value } = e.target;
      const modifiedValue = value === "" ? null : value;
      setBankDetailsValues((prevbankDetailsValues) => {
        if (name) {
          const newValues = {
            ...prevbankDetailsValues,
            [name]: uppercase ? value.toUpperCase() : modifiedValue,
          };
          return newValues;
        } else {
          return prevbankDetailsValues;
        }
      });
    },
    []
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleFormNavigationClick = async (step: number) => {
    const requiredFieldsForStep: string[] = requiredFields
      .map((field) => {
        if (Object.keys(bankDetailsValues).includes(field)) {
          return field;
        }
        return undefined;
      })
      .filter(
        (field): field is string => field !== undefined && field !== null
      );

    const hasMissingFields = requiredFieldsForStep.some(
      (field) =>
        !validateField(bankDetailsValues[field as keyof VendorBankDetails])
    );

    if (hasMissingFields) {
      handleSnackbar("error", "Please fill in all required fields.");
      return;
    }

    const isAccountNumberValid = bankDetailsValues.accountNumber?.length === 11;
    const isConfirmAccountNumberValid =
      bankDetailsValues.confirmAccountNumber ===
      bankDetailsValues.accountNumber;
    const isIFSCCodeValid = isIFSCValid(bankDetailsValues.ifscCode);

    if (
      !isAccountNumberValid ||
      !isConfirmAccountNumberValid ||
      !isIFSCCodeValid
    ) {
      handleSnackbar(
        "error",
        "Please fix the errors in the form before proceeding."
      );
      return;
    }

    HandleEmptyStringValues();

    try {
      for (const [key, value] of Object.entries(bankDetailsValues)) {
        if (
          value instanceof File &&
          fileFields.includes(key as keyof VendorBankDetails)
        ) {
          const image = new FormData();
          image.append(key, value);
          image.append(
            "additionalFileData",
            JSON.stringify({ metadata: "Vendor Document" })
          );
          const imageUploadResponse: any = await uploadFileWithMetadata(image);
          if (imageUploadResponse.ok) {
            const imageUrl = await imageUploadResponse.json();
            bankDetailsValues[key as keyof VendorBankDetails] = imageUrl
              .uploadedFileData.fileUrl as never;
          } else {
            handleSnackbar("error", "Error in uploading documents");
            throw new Error("Error in uploading document");
          }
        }
      }
      try {
        if (vendorId && activeStep < steps.length) {
          if (authHeader) {
            await updateVendorDetails(vendorId, bankDetailsValues, authHeader);
          } else {
            throw new Error("User not recognized");
          }
        }
        handleStepChange(step);
      } catch (error) {
        handleSnackbar("error", "Errorin saving vendor details");
        throw new Error(`Error in saving vendor profile details : ${error}`);
      }
    } catch (error) {
      console.error("Error in saving vendor details : ", error);
      handleSnackbar("error", "Error in saving vendor details");
    }
  };

  const onFileInputChange = useCallback((name: string, value: File | null) => {
    setBankDetailsValues((prevbankDetailsValues) => {
      if (name && value) {
        const newValues = { ...prevbankDetailsValues, [name]: value };
        return newValues;
      } else {
        return prevbankDetailsValues;
      }
    });
  }, []);
  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
        Bank Details :
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 200px)"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Account Number"
                name="accountNumber"
                variant="outlined"
                fullWidth
                required
                value={bankDetailsValues.accountNumber}
                onChange={handleTextInputChange}
                inputProps={{
                  maxLength: 11,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={
                  bankDetailsValues.accountNumber !== null &&
                  bankDetailsValues.accountNumber.length !== 11
                }
                helperText={
                  bankDetailsValues.accountNumber !== null &&
                  bankDetailsValues.accountNumber.length !== 11
                    ? "Account Number Invalid"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Confirm Account Number"
                name="confirmAccountNumber"
                variant="outlined"
                fullWidth
                required
                value={bankDetailsValues.confirmAccountNumber}
                onChange={handleTextInputChange}
                disabled={bankDetailsValues.accountNumber === ""}
                inputProps={{
                  maxLength: 11,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={
                  bankDetailsValues.accountNumber !== "" &&
                  bankDetailsValues.confirmAccountNumber !== "" &&
                  bankDetailsValues.accountNumber !==
                    bankDetailsValues.confirmAccountNumber
                }
                helperText={
                  bankDetailsValues.accountNumber !== "" &&
                  bankDetailsValues.confirmAccountNumber !== "" &&
                  bankDetailsValues.accountNumber !==
                    bankDetailsValues.confirmAccountNumber
                    ? "Account Number Does Not Match"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Account Holder Name"
                name="accountHolderName"
                variant="outlined"
                fullWidth
                required
                value={bankDetailsValues.accountHolderName}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="IFSC Code"
                name="ifscCode"
                variant="outlined"
                fullWidth
                required
                value={bankDetailsValues.ifscCode}
                onChange={(e) => handleTextInputChange(e, true)}
                sx={{ textTransform: "uppercase" }}
                inputProps={{ maxLength: 11 }}
                error={!isIFSCValid(bankDetailsValues.ifscCode)}
                helperText={
                  !isIFSCValid(bankDetailsValues.ifscCode)
                    ? "Invalid IFSC Code"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Bank Name"
                name="bankName"
                variant="outlined"
                fullWidth
                required
                value={bankDetailsValues.bankName}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload Cancelled Cheque Copy"
                name="cancelledChequeCopy"
                value={bankDetailsValues.cancelledChequeCopy}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Bank Address"
                name="bankAddress"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={bankDetailsValues.bankAddress}
                onChange={handleTextInputChange}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: 5 }}>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep + 1)}
            >
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Grid>
        </>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default BankDetails;
