import {
  SelectChangeEvent,
  Box,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState, useRef, useCallback, useEffect } from "react";
import getVendorDetails from "../../clients/getVendorDetails";
import { updateVendorDetails } from "../../clients/updateVendorDetails";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import {
  fileFields,
  requiredFields,
  steps,
  taxAndComplainceDefaultValues,
} from "./vendorConstants";
import { VendorFileInput } from "./VendorFileInput";
import { VendorTaxAndCompliance, Vendors } from "./vendorTypes";
import { isGstValid, isPanValid, isTanValid, validateField } from "./vendorValidators";
import { saveVendorDetails } from "../../clients/saveVendorDetails";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import useHandleEmptyStringValues from "./CustomHook";
import { useAuthContext } from "../../providers/AuthProvider";

interface TaxAndComplianceProps {
  activeStep: number;
  handleStepChange: (step: number) => Promise<void>;
  vendorId?: string;
  setVendorIdState: (value: React.SetStateAction<string | undefined>) => void;
}

const TaxAndCompliance = ({
  activeStep,
  handleStepChange,
  vendorId,
  setVendorIdState,
}: TaxAndComplianceProps) => {
  const { authHeader } = useAuthContext();
  const [taxAndComplianceValues, setTaxAndComplianceValues] =
    useState<VendorTaxAndCompliance>(taxAndComplainceDefaultValues);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasFetchedData = useRef(false);

  const HandleEmptyStringValues = () =>
    useHandleEmptyStringValues({
      values: taxAndComplianceValues,
      setValue: setTaxAndComplianceValues,
    });

  const handleSnackbar = useCallback((severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const fetchFile = useCallback(async (url: string, field: string) => {
    try {
      const fileResponse = await fetch(url, { method: "GET" });
      if (!fileResponse.ok) {
        throw new Error(`File for ${field} could not be fetched`);
      }
      const arrayBuffer = await fileResponse.arrayBuffer();
      const contentType = fileResponse.headers.get("content-type") || undefined;
      return new File([arrayBuffer], field, { type: contentType });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  }, []);

  const fetchBasicInformationDetails = useCallback(
    async (vendorId: string) => {
      setLoading(true);
      try {
        const response: any = await getVendorDetails(vendorId);
        const vendorDetails: Vendors = response.data.vendorDetails;
        const taxAndComplianceKeys = Object.keys(taxAndComplainceDefaultValues);

        Object.keys(vendorDetails).forEach((key) => {
          if (
            !taxAndComplianceKeys.includes(key as keyof VendorTaxAndCompliance)
          )
            delete vendorDetails[key as keyof Vendors];
        });
        const taxAndComplianceData: VendorTaxAndCompliance =
          vendorDetails as VendorTaxAndCompliance;

        const taxAndComplianceFields: (keyof VendorTaxAndCompliance)[] =
          fileFields.filter((field) =>
            taxAndComplianceKeys.includes(field as keyof VendorTaxAndCompliance)
          ) as (keyof VendorTaxAndCompliance)[];

        for (const field of taxAndComplianceFields) {
          const fieldValue = taxAndComplianceData[field];
          if (fieldValue) {
            const file = await fetchFile(String(fieldValue), field);
            if (file) {
              (taxAndComplianceData[field] as File | string | null) = file;
            }
          }
        }

        setTaxAndComplianceValues(taxAndComplianceData);
      } catch (error) {
        console.error("Error in fetching basic information : ", error);
        handleSnackbar("error", "Error in fetching basic information");
      } finally {
        setLoading(false);
      }
    },
    [fetchFile, handleSnackbar]
  );

  useEffect(() => {
    if (vendorId && !hasFetchedData.current) {
      fetchBasicInformationDetails(vendorId);
      hasFetchedData.current = true;
    }
  }, [vendorId, fetchBasicInformationDetails]);

  const handleTextInputChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      uppercase?: boolean
    ) => {
      const { name, value } = e.target;
      const modifiedValue = value === "" ? null : value;
      setTaxAndComplianceValues((prevtaxAndComplianceValues) => {
        if (name) {
          const newValues = {
            ...prevtaxAndComplianceValues,
            [name]: uppercase ? value.toUpperCase() : modifiedValue,
          };
          return newValues;
        } else {
          return prevtaxAndComplianceValues;
        }
      });
    },
    []
  );

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<number | null>) => {
      const { name, value } = e.target;
      setTaxAndComplianceValues((prevtaxAndComplianceValues) => {
        if (name) {
          if (value === "")
            return { ...prevtaxAndComplianceValues, [name]: null };
          const newValues = { ...prevtaxAndComplianceValues, [name]: value };
          return newValues;
        } else {
          return prevtaxAndComplianceValues;
        }
      });
    },
    []
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleFormNavigationClick = async (step: number) => {
    const requiredFieldsForStep: string[] = requiredFields
      .map((field) => {
        if (Object.keys(taxAndComplainceDefaultValues).includes(field)) {
          return field;
        }
        return undefined;
      })
      .filter(
        (field): field is string => field !== undefined && field !== null
      );

    const hasMissingFields = requiredFieldsForStep.some(
      (field) =>
        !validateField(
          taxAndComplianceValues[field as keyof VendorTaxAndCompliance]
        )
    );

    if (hasMissingFields) {
      handleSnackbar("error", "Please fill in all required fields.");
      return;
    }

    if (taxAndComplianceValues.isGstAvailable === 0) {
      taxAndComplianceValues.gstNumber = null;
      taxAndComplianceValues.taxSection206AB206CCA = null;
      taxAndComplianceValues.gstCertCopy = null;
    }

    HandleEmptyStringValues();

    try {
      for (const [key, value] of Object.entries(taxAndComplianceValues)) {
        if (
          value instanceof File &&
          fileFields.includes(key as keyof VendorTaxAndCompliance)
        ) {
          const image = new FormData();
          image.append(key, value);
          image.append(
            "additionalFileData",
            JSON.stringify({ metadata: "Vendor Document" })
          );
          const imageUploadResponse: any = await uploadFileWithMetadata(image);
          if (imageUploadResponse.ok) {
            const imageUrl = await imageUploadResponse.json();
            taxAndComplianceValues[key as keyof VendorTaxAndCompliance] =
              imageUrl.uploadedFileData.fileUrl as never;
          } else {
            handleSnackbar("error", "Error in uploading documents");
            throw new Error("Error in uploading document");
          }
        }
      }
      try {
        if (vendorId && activeStep < steps.length) {
          if (authHeader)
            await updateVendorDetails(
              vendorId,
              taxAndComplianceValues,
              authHeader
            );
          else throw new Error("User not recognized");
        }
        if (activeStep === 0 && !vendorId) {
          if (authHeader) {
            const response: any = await saveVendorDetails(
              taxAndComplianceValues,
              authHeader
            );
            const data = response.data;
            if (!vendorId) {
              setVendorIdState(data.vendorDetails["vendorId"]);
            }
          } else throw new Error("User not recognized");
        }
        handleStepChange(step);
      } catch (error) {
        handleSnackbar("error", "Errorin saving vendor details");
        throw new Error(`Error in saving vendor profile details : ${error}`);
      }
    } catch (error) {
      console.error("Error in saving vendor details : ", error);
      handleSnackbar("error", "Error in saving vendor details");
    }
  };

  const onFileInputChange = useCallback((name: string, value: File | null) => {
    setTaxAndComplianceValues((prevtaxAndComplianceValues) => {
      if (name && value) {
        const newValues = { ...prevtaxAndComplianceValues, [name]: value };
        return newValues;
      } else if (name) {
        const newValues = { ...prevtaxAndComplianceValues, [name]: null };
        return newValues;
      } else return prevtaxAndComplianceValues;
    });
  }, []);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxAndComplianceValues((prevtaxAndComplianceValues) => ({
      ...prevtaxAndComplianceValues,
      isGstAvailable: e.target.checked ? 0 : 1,
    }));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
        Tax and Compliance :
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 90px)"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="GST Number"
                name="gstNumber"
                variant="outlined"
                fullWidth
                required={taxAndComplianceValues.isGstAvailable === 1}
                value={taxAndComplianceValues.gstNumber}
                onChange={(e) => handleTextInputChange(e, true)}
                sx={{ textTransform: "uppercase" }}
                inputProps={{ maxLength: 15 }}
                error={
                  taxAndComplianceValues.gstNumber !== null &&
                  !isGstValid(taxAndComplianceValues.gstNumber) &&
                  taxAndComplianceValues.isGstAvailable === 1
                }
                helperText={
                  taxAndComplianceValues.gstNumber !== null &&
                  taxAndComplianceValues.isGstAvailable === 1 &&
                  !isGstValid(taxAndComplianceValues.gstNumber)
                    ? "GST Number Format Invalid"
                    : ""
                }
                disabled={taxAndComplianceValues.isGstAvailable === 0}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required={taxAndComplianceValues.isGstAvailable === 1}
              >
                <InputLabel id="taxSection206AB206CCA-label">
                  Tax Section 206AB/206CCA
                </InputLabel>
                <Select
                  labelId="taxSection206AB206CCA-label"
                  label="Tax Section 206AB/206CCA"
                  name="taxSection206AB206CCA"
                  value={taxAndComplianceValues.taxSection206AB206CCA}
                  onChange={handleSelectChange}
                  disabled={taxAndComplianceValues.isGstAvailable === 0}
                >
                  <MenuItem value="">SELECT</MenuItem>
                  <MenuItem value={1}>YES</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload GST Certificate Copy"
                name="gstCertCopy"
                value={taxAndComplianceValues.gstCertCopy}
                disable={
                  !(
                    taxAndComplianceValues.taxSection206AB206CCA &&
                    taxAndComplianceValues.isGstAvailable === 1
                  )
                }
                required={taxAndComplianceValues.isGstAvailable === 1}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={taxAndComplianceValues.isGstAvailable === 0}
                    onChange={handleCheckboxChange}
                  />
                }
                label="I do not have a GST number and I want to proceed without GST information."
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="PAN Number"
                name="panNumber"
                variant="outlined"
                fullWidth
                required
                value={taxAndComplianceValues.panNumber}
                onChange={(e) => handleTextInputChange(e, true)}
                sx={{ textTransform: "uppercase" }}
                inputProps={{ maxLength: 10 }}
                error={!isPanValid(taxAndComplianceValues.panNumber)}
                helperText={
                  isPanValid(taxAndComplianceValues.panNumber)
                    ? ""
                    : "PAN Number Format Invalid"
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload PAN Copy"
                name="panCopy"
                value={taxAndComplianceValues.panCopy}
                disable={!taxAndComplianceValues.panNumber}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="TAN Number"
                name="tanNumber"
                variant="outlined"
                fullWidth
                value={taxAndComplianceValues.tanNumber}
                onChange={(e) => handleTextInputChange(e, true)}
                sx={{ textTransform: "uppercase" }}
                inputProps={{ maxLength: 10 }}
                error={
                  taxAndComplianceValues.tanNumber !== null &&
                  !isTanValid(taxAndComplianceValues.tanNumber)
                }
                helperText={
                  taxAndComplianceValues.tanNumber !== null &&
                  !isTanValid(taxAndComplianceValues.tanNumber)
                    ? "TAN Number Format Invalid"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload TAN Copy"
                name="tanCopy"
                value={taxAndComplianceValues.tanCopy}
                disable={!taxAndComplianceValues.tanNumber}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="isRegisteredWithMSME-label">
                  Registered with MSME
                </InputLabel>
                <Select
                  labelId="isRegisteredWithMSME-label"
                  id="isRegisteredWithMSME"
                  name="isRegisteredWithMSME"
                  value={taxAndComplianceValues.isRegisteredWithMSME}
                  onChange={handleSelectChange}
                  label="Registered with MSME"
                >
                  <MenuItem value="">SELECT</MenuItem>
                  <MenuItem value={1}>YES</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="MSME Number"
                name="msmeNumber"
                variant="outlined"
                fullWidth
                value={taxAndComplianceValues.msmeNumber}
                onChange={handleTextInputChange}
                disabled={!taxAndComplianceValues.isRegisteredWithMSME}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload MSME Certificate Copy"
                name="msmeCertCopy"
                value={taxAndComplianceValues.msmeCertCopy}
                disable={
                  !(
                    taxAndComplianceValues.isRegisteredWithMSME &&
                    taxAndComplianceValues.msmeNumber
                  )
                }
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="lowerTdsCert-label">
                  Lower TDS Certificate
                </InputLabel>
                <Select
                  labelId="lowerTdsCert-label"
                  id="lowerTdsCert"
                  name="lowerTdsCert"
                  value={taxAndComplianceValues.lowerTdsCert}
                  onChange={handleSelectChange}
                  label="Lower TDS Certificate"
                >
                  <MenuItem value="">SELECT</MenuItem>
                  <MenuItem value={1}>YES</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload Lower TDS Certificate Copy"
                name="lowerTdsCertCopy"
                value={taxAndComplianceValues.lowerTdsCertCopy}
                disable={!taxAndComplianceValues.lowerTdsCert}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="SAC/HSN"
                name="sacHsn"
                variant="outlined"
                fullWidth
                value={taxAndComplianceValues.sacHsn}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload Tax Declaration 206AB"
                name="taxDeclaration206AB"
                value={taxAndComplianceValues.taxDeclaration206AB}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: 5 }}>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep + 1)}
            >
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Grid>
        </>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default TaxAndCompliance;
