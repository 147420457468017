import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";

const UnauthorizedMessage: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleCreateClick = () => {
    navigate("/sign-up");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFEFC",
          padding: 3,
          border: "2px solid",
          borderRadius: 2,
          textAlign: "center",
          maxWidth: "700px",
          width: "100%",
        }}
      >
        <LockIcon sx={{ fontSize: 60, color: "", marginBottom: 2 }} />
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Access Restricted
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 3 }} color="error">
          Only authorized users can access this console. Please log in to use
          the available features. <br />
          If you are new user create your account to access.
        </Typography>
        <Box width="100%" display="flex" justifyContent="space-evenly">
          <Button variant="outlined" onClick={handleCreateClick}>
            Create Account
          </Button>
          <Button variant="contained" onClick={handleLoginClick}>
            Login to Access
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UnauthorizedMessage;
