import { VendorTaxAndCompliance } from "../components/Vendors/vendorTypes";
import { GingerMediaBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function saveVendorDetails(formData: VendorTaxAndCompliance, authHeader: string) {
    return fetchWrapper(`${GingerMediaBaseUrl}/saveVendorDetails`,
        {
            method: 'POST',
            headers: {
                Authorization: authHeader,
            },
            body: formData
        }
    );
}