import { fetchWrapper } from "./fetchWrapper";
import { GingerMediaBaseUrl } from "./baseUrls";

export async function getMediaDetailsByService(service: string, offset: number, sortKey?: string, sortOrder?: "asc" | "desc") {
    if (sortKey && sortOrder) {
        return fetchWrapper(`${GingerMediaBaseUrl}/getMediaDetailsByService?service=${service}&offset=${offset}&sortKey=${sortKey}&sortOrder=${sortOrder}`, {
            method: 'GET',
        });
    }
    return fetchWrapper(`${GingerMediaBaseUrl}/getMediaDetailsByService?service=${service}&offset=${offset}`, {
        method: 'GET',
    });
}