import React, { useState } from "react";
import { styled } from "@mui/system";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  CardContent,
  Card,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Mediums,
  ServiceMediums,
  ServiceNames,
} from "../../utils/serviceNames";
import imageCompression from "browser-image-compression";
import DeleteIcon from "@mui/icons-material/Delete";
import { createMediaDetails } from "../../clients/createMediaDetails";
import { mediaDataFormDefaultValues } from "./MediaDataConstants";
import { useAuthContext } from "../../providers/AuthProvider";

interface ImageData {
  image: File | null;
  tags: string;
  location: string;
  additionalInfo: string;
}

interface MediaForm {
  service: string | null;
  medium: string | null;
  type: string | null;
  areaInSqFeet: number | null;
  landmark: string | null;
  location: string | null;
  latitude: number | null;
  longitude: number | null;
  city: string | null;
  area: string | null;
  state: string | null;
  pincode: number | null;
  displayCostPerMonth: number | null;
  vendorId: string | null;
  vendorName: string | null;
  vendorAssetCode: string | null;
  mountingPrice: number | null;
  printingCharge: number | null;
  towards: string | null;
  width: number | null;
  height: number | null;
}

interface DynamicField {
  id: number;
  name: string;
  value: string;
}

const MediaDataForm: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [formData, setFormData] = useState<MediaForm>(
    mediaDataFormDefaultValues
  );
  const [imageData, setImageData] = useState<ImageData>({
    image: null,
    tags: "",
    location: "",
    additionalInfo: "",
  });
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          setImageData({ ...imageData, image: compressedFile });
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
    }
  };

  const handleImageMetaData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setImageData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDynamicFieldChange = (
    id: number,
    key: keyof DynamicField,
    value: string
  ) => {
    setDynamicFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, [key]: value } : field
      )
    );
  };

  const addDynamicField = () => {
    setDynamicFields((prevFields) => [
      ...prevFields,
      { id: counter, name: "", value: "" },
    ]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeDynamicField = (id: number) => {
    setDynamicFields((prevFields) =>
      prevFields.filter((field) => field.id !== id)
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const mediaDetails: { [key: string]: number | null | string } = {};
      const additionalMediaInfo: any = {};
      const metadata: any = {};
      const additionalFileData: { tags?: string; metadata?: string } = {};

      Object.keys(formData).forEach((key) => {
        const value = (formData as any)[key];
        if (key !== "service") {
          mediaDetails[key] = value;
        }
      });

      if (dynamicFields && dynamicFields.length > 0) {
        dynamicFields.forEach((field) => {
          additionalMediaInfo[field.name] = field.value;
        });
        mediaDetails["additionalDetails"] =
          Object.keys(additionalMediaInfo).length > 0
            ? JSON.stringify(additionalMediaInfo)
            : null;
      }

      const imageFileData = new FormData();
      if (!imageData.image) {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Please select an image file");
        }, 100);
        return;
      } else imageFileData.append("image", imageData.image);

      if (imageData.location) {
        metadata["location"] = imageData.location;
      }
      if (imageData.additionalInfo) {
        metadata["additionalInfo"] = imageData.additionalInfo;
      }
      if (imageData.tags) {
        additionalFileData.tags = imageData.tags;
      }
      if (Object.keys(metadata).length > 0) {
        additionalFileData.metadata = metadata;
      }

      imageFileData.append(
        "additionalFileData",
        JSON.stringify(additionalFileData)
      );
      if (authHeader) {
        const response: any = await uploadFileWithMetadata(imageFileData);
        const uploadedFileData: any = await response.json();

        if (response.ok) {
          mediaDetails["imageUrl"] = uploadedFileData.uploadedFileData.fileUrl;
        } else {
          handleSnackbar("error", "Failed to Upload Image");
          console.error("Failed to upload image.");
        }

        if (formData && formData.service && formData.medium) {
          const uploadForm: any = await createMediaDetails(
            formData.service,
            formData.medium,
            mediaDetails,
            authHeader
          );
          if (uploadForm.data) {
            handleSnackbar("success", "Media Details Submitted Successfully");
            handleClearAll();
          } else {
            handleSnackbar("error", "Error in submitting media details");
          }
        } else {
          handleSnackbar("error", "Failed to Submit Form");
          console.error("Failed to submit form.");
        }
      } else {
        throw new Error("User not recognized");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        setImageData({ ...imageData, image: compressedFile });
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleRemoveImage = () => {
    setImageData({ ...imageData, image: null });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleClearAll = () => {
    setFormData(mediaDataFormDefaultValues);
    setImageData({
      image: null,
      tags: "",
      location: "",
      additionalInfo: "",
    });
  };

  const DragAndDropArea = styled("div")(() => ({
    border: "2px dashed orange",
    borderRadius: "8px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "320px",
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      "& p": {
        color: "orange",
        fontWeight: "bold",
      },
    },
  }));

  const PreviewImage = styled("img")(() => ({
    maxWidth: "100%",
    maxHeight: "80%",
  }));

  const RemoveButton = styled(Button)({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    height: "10%",
    bottom: "8px",
  });

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card sx={{ maxWidth: 1200, width: "100%", border: "1px solid " }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: "16px" }}
          >
            <Typography variant="h5" gutterBottom>
              Upload Single Data Form
            </Typography>
            <Button type="button" variant="outlined" onClick={handleClearAll}>
              Clear All
            </Button>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="service"
                  label="Service"
                  select
                  value={formData.service}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                >
                  {Object.values(ServiceNames).map(
                    (service: string, index: number) => (
                      <MenuItem key={index} value={service}>
                        {service.toUpperCase()}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="medium"
                  label="Medium"
                  select
                  value={formData.medium}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                >
                  {formData.service &&
                    ServiceMediums[
                      formData.service as keyof typeof ServiceMediums
                    ]?.map((medium: string, index: number) => (
                      <MenuItem key={index} value={medium}>
                        {Mediums[medium as keyof typeof Mediums].toUpperCase()}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              {formData.service === "outdoor" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      name="type"
                      label="Type"
                      select
                      value={formData.type}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    >
                      <MenuItem value="lit">Lit</MenuItem>
                      <MenuItem value="backLit">Back Lit</MenuItem>
                      <MenuItem value="frontLit">Front Lit</MenuItem>
                      <MenuItem value="led">LED</MenuItem>
                      <MenuItem value="nonLit">Non Lit</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="width"
                      label="Width"
                      type="number"
                      value={formData.width}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="height"
                      label="Height"
                      type="number"
                      value={formData.height}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="areaInSqFeet"
                      label="Total Sqft"
                      type="number"
                      value={formData.areaInSqFeet}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="state"
                      label="State"
                      value={formData.state}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="city"
                      label="City"
                      value={formData.city}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="area"
                      label="Area"
                      value={formData.area}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="location"
                      label="Location"
                      value={formData.location}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="towards"
                      label="Towards"
                      value={formData.towards}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="landmark"
                      label="Landmark"
                      value={formData.landmark}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="pincode"
                      label="Pincode"
                      type="number"
                      value={formData.pincode}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="latitude"
                      label="Latitude"
                      type="number"
                      value={formData.latitude}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="longitude"
                      label="Longitude"
                      type="number"
                      value={formData.longitude}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="displayCostPerMonth"
                      label="Display Cost Per Month"
                      type="number"
                      value={formData.displayCostPerMonth || ""}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="mountingPrice"
                      label="Mounting Price"
                      type="number"
                      value={formData.mountingPrice || ""}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="printingCharge"
                      label="Printing Charge"
                      type="number"
                      value={formData.printingCharge || ""}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="vendorId"
                      label="Vendor ID"
                      value={formData.vendorId}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="vendorAssetCode"
                      label="Vendor Asset Code"
                      value={formData.vendorAssetCode}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={addDynamicField}
                >
                  Add Field
                </Button>
              </Grid>

              {dynamicFields.map((field) => (
                <Grid
                  container
                  spacing={2}
                  key={field.id}
                  alignItems="center"
                  item
                  xs={12}
                >
                  <Grid item xs={4}>
                    <TextField
                      label="Field Name"
                      value={field.name}
                      onChange={(e) =>
                        handleDynamicFieldChange(
                          field.id,
                          "name",
                          e.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      label="Field Value"
                      value={field.value}
                      onChange={(e) =>
                        handleDynamicFieldChange(
                          field.id,
                          "value",
                          e.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      edge="end"
                      onClick={() => removeDynamicField(field.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={7} display="flex" justifyContent="center">
                <DragAndDropArea
                  onDragOver={handleDragOver}
                  onDrop={imageData.image ? undefined : handleDrop}
                  onClick={
                    imageData.image
                      ? undefined
                      : () => document.getElementById("image")?.click()
                  }
                  sx={{
                    cursor: imageData.image ? "not-allowed" : "pointer",
                    width: "600px",
                    height: "400px",
                  }}
                >
                  {imageData.image ? (
                    <>
                      <PreviewImage
                        src={URL.createObjectURL(imageData.image)}
                        alt="Preview"
                      />
                      <RemoveButton onClick={handleRemoveImage}>
                        Remove
                      </RemoveButton>
                    </>
                  ) : (
                    <Typography>
                      Drag & Drop Image file here, or click to select
                    </Typography>
                  )}
                </DragAndDropArea>
                <input
                  type="file"
                  id="image"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  required
                />
              </Grid>
              <Grid
                item
                xs={5}
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <TextField
                  name="tags"
                  label="Tags"
                  value={imageData.tags}
                  onChange={handleImageMetaData}
                  fullWidth
                  margin="normal"
                  disabled={!imageData.image}
                />
                <TextField
                  name="location"
                  label="Location"
                  value={imageData.location}
                  onChange={handleImageMetaData}
                  fullWidth
                  margin="normal"
                  disabled={!imageData.image}
                />
                <TextField
                  name="additionalInfo"
                  label="Additional Information"
                  value={imageData.additionalInfo}
                  onChange={handleImageMetaData}
                  fullWidth
                  margin="normal"
                  disabled={!imageData.image}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default MediaDataForm;
