import { SignUpValues } from "../components/signUp/SignUpValues";
import { AuthApiBaseUrl, clientId } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function createUsers(
  formValues: SignUpValues,
  authHeader: string
) {
  const url = `${AuthApiBaseUrl}/createUser?clientId=${clientId}`;

  return fetchWrapper(url, {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: authHeader,
    },
  });
}
