import { VendorBankDetails, VendorBasicInformation, VendorBusinessAndFinance, VendorOtherDocuments, VendorTaxAndCompliance } from "../components/Vendors/vendorTypes";
import { GingerMediaBaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function updateVendorDetails(vendorId: string, formData: VendorBankDetails | VendorBasicInformation | VendorBusinessAndFinance | VendorOtherDocuments | VendorTaxAndCompliance, authHeader: string) {
    return fetchWrapper(`${GingerMediaBaseUrl}/updateVendorDetails?vendorId=${vendorId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authHeader,
            },
            body: formData
        }
    );
}