import React from "react";
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { keyframes } from "@emotion/react";

// Keyframes for animations
const checkmarkAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

const crossmarkAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

const partialSuccessAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

// Custom styles
const ModalStyled = styled(Modal)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CardStyled = styled(Card)(({ theme }) => ({
  width: 600,
  height: 200,
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const CardContentStyled = styled(CardContent)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const CardActionsStyled = styled(CardActions)(() => ({
  justifyContent: "space-between",
}));

const Checkmark = styled("svg")(({ theme }) => ({
  width: "50px",
  height: "50px",
  marginBottom: theme.spacing(2),
  stroke: theme.palette.success.main,
  strokeWidth: 4,
  fill: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  animation: `${checkmarkAnimation} 1s ease-in-out`,
}));

const Crossmark = styled("svg")(({ theme }) => ({
  width: "50px",
  height: "50px",
  marginBottom: theme.spacing(2),
  stroke: theme.palette.error.main,
  strokeWidth: 4,
  fill: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  animation: `${crossmarkAnimation} 1s ease-in-out`,
}));

const PartialSuccess = styled("svg")(({ theme }) => ({
  width: "50px",
  height: "50px",
  marginBottom: theme.spacing(2),
  stroke: "#ff9800",
  strokeWidth: 4,
  fill: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  animation: `${partialSuccessAnimation} 1s ease-in-out`,
}));

interface UploadResultModalProps {
  open: boolean;
  onClose: () => void;
  successCount: number;
  failedCount: number;
  onUploadMore: () => void;
  onGoBack: () => void;
}

const UploadResultModal: React.FC<UploadResultModalProps> = ({
  open,
  onClose,
  successCount,
  failedCount,
  onUploadMore,
  onGoBack,
}) => {
  const isSuccess = successCount > 0 && failedCount === 0;
  const isFailure = successCount === 0 && failedCount > 0;
  const isPartialSuccess = successCount > 0 && failedCount > 0;

  return (
    <ModalStyled open={open} onClose={onClose}>
      <CardStyled>
        <CardContentStyled>
          {isSuccess ? (
            <>
              <Checkmark viewBox="0 0 24 24">
                <path d="M5 13l4 4L19 7" />
              </Checkmark>
              <Typography variant="h6">
                All rows uploaded successfully! {successCount} row(s) were
                uploaded.
              </Typography>
            </>
          ) : isFailure ? (
            <>
              <Crossmark viewBox="0 0 24 24">
                <path d="M7 7l10 10M7 17L17 7" />
              </Crossmark>
              <Typography variant="h6">
                Upload failed. {failedCount} row(s) failed.
              </Typography>
            </>
          ) : isPartialSuccess ? (
            <>
              <PartialSuccess viewBox="0 0 24 24">
                <path d="M12 2v20M2 12h20" />
              </PartialSuccess>
              <Typography variant="h6">
                Upload completed with some errors. {successCount} row(s) were
                successfully uploaded, and {failedCount} row(s) failed.
              </Typography>
            </>
          ) : null}
        </CardContentStyled>
        <CardActionsStyled sx={{ gap: "50px", margin: "0 10px" }}>
          <Button
            fullWidth
            onClick={onUploadMore}
            color="primary"
            variant="contained"
          >
            Upload More
          </Button>
          <Button
            fullWidth
            onClick={onGoBack}
            color="primary"
            variant="outlined"
          >
            Go Back
          </Button>
          <Button
            fullWidth
            onClick={onClose}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </CardActionsStyled>
      </CardStyled>
    </ModalStyled>
  );
};

export default UploadResultModal;
