import { Vendors } from "./vendorTypes";

export const steps = [
    "taxAndComplaince",
    "basicInformation",
    "businessAndFinance",
    "bankDetails",
    "otherOtherDocuments",
];

export const fileFields: (keyof Vendors)[] = [
    "incorporationCertCopy",
    "msmeCertCopy",
    "gstCertCopy",
    "panCopy",
    "tanCopy",
    "cancelledChequeCopy",
    "otherOtherDocuments",
    "latestFinancials",
    "lowerTdsCertCopy",
    "taxDeclaration206AB",
];

export const basicInformationDefaultValues = {
    vendorName: "",
    vendorContactNumber: "",
    vendorEmail: "",
    firmName: "",
    organizationType: "",
    incorporationDate: "",
    incorporationCertCopy: null,
    proprietorName: "",
    registeredAddress: "",
    billingAddress: "",
    shippingAddress: "",
    businessNature: "",
};

export const businessAndFinanceDefaultValues = {
    businessContactName: "",
    businessDesignation: "",
    businessContactNumber: "",
    businessTelephone: "",
    businessEmail: "",
    financeContactName: "",
    financeDesignation: "",
    financeContactNumber: "",
    financeEmail: "",
};

export const bankDetailsDefaultValues = {
    accountNumber: "",
    confirmAccountNumber: "",
    accountHolderName: "",
    ifscCode: "",
    bankName: "",
    bankAddress: "",
    cancelledChequeCopy: null,
};

export const otherDocumentsDefaultValues = {
    otherOtherDocuments: null,
    latestFinancials: null,
    formPublisherEditUrl: null,
    formPublisherDocUrl: null,
    formPublisherPdfUrl: null,
};

export const taxAndComplainceDefaultValues = {
    gstNumber: "",
    registrationType: "",
    isRegisteredWithMSME: null,
    msmeNumber: "",
    msmeCertCopy: null,
    taxSection206AB206CCA: null,
    gstCertCopy: null,
    sacHsn: "",
    panNumber: "",
    panCopy: null,
    tanNumber: "",
    tanCopy: null,
    lowerTdsCert: null,
    lowerTdsCertCopy: null,
    taxDeclaration206AB: null,
    isGstAvailable: 1,
};

export const requiredFields = [
    "panNumber",
    "businessContactName",
    "businessContactNumber",
    "vendorEmail",
    "accountNumber",
    "confirmAccountNumber",
    "accountHolderName",
    "ifscCode",
    "bankName",
    "firmName",
];
