import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Typography,
  Box,
  Button,
  TableBody,
  Checkbox,
  MenuItem,
  InputBase,
  IconButton,
  Pagination,
  Modal,
  Menu,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import dateformat from "dateformat";
import { queryFilter } from "../../clients/queryFilter";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { searchAutoComplete } from "../../clients/searchAutoComplete";
import FilterComponent from "./FilterComponent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditDetails from "./EditDetails";
import { deleteData } from "../../clients/deleteData";
import TuneIcon from "@mui/icons-material/Tune";
import CustomSplitButton from "./ButtonGroup";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import FileDownloadOffTwoToneIcon from "@mui/icons-material/FileDownloadOffTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomSnackbar from "../snackbar/CustomSnackbar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "5px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px 10px",
    maxWidth: "300px",
  },
}));

const FirstColumnStyledTableCell = styled(StyledTableCell)({
  borderRight: "1px solid #ddd",
  width: "230px",
});

const RWAConsole: React.FC = () => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [rwaData, setRwaData] = useState<any[]>([]);
  const history = useNavigate();
  const [search, setSearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const [totalCount, setTotalCount] = useState(0);
  const [filterData, setFilterData] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editModal, setEditModal] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [selectedRwaId, setSelectedRwaId] = useState(null);
  const [removedRwaIds, setRemovedRwaIds] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarIcon, setSnackbarIcon] = useState(null);

  const handleFilterChange = (field: string, value: string) => {
    setFilterData((prevFilterData: any) => ({
      ...prevFilterData,
      [field]: value,
    }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearch(searchText);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        if (searchText.trim() === "") {
          fetchData(1, filterData);
        } else {
          fetchSearchResults(searchText);
        }
      }, 1000)
    );
  };

  const handleUploadCsv = () => {
    history(`/data-dashboard`);
  };

  const handleDownloadCSV = async () => {
    try {
      const response: any = await queryFilter(1, totalCount, filterData);
      const responseData = response.data?.items || [];

      if (responseData.length > 0) {
        let csvContent = Object.keys(responseData[0]).join(",") + "\n";

        responseData.forEach((row: any) => {
          const values = Object.values(row).map((value) => {
            if (typeof value === "string" && value.includes(",")) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
          });
          csvContent += values.join(",") + "\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "selected_rows.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
        handleSnackbar(
          "success",
          "RWA details downloaded successfully",
          <DownloadTwoToneIcon />
        );
      } else {
        handleSnackbar(
          "error",
          "No data available for download",
          <FileDownloadOffTwoToneIcon />
        );
      }
    } catch (error) {
      handleSnackbar(
        "error",
        "Error downloading data, Please try again",
        <FileDownloadOffTwoToneIcon />
      );
    }
  };

  const handleCheckboxChange = (row: any) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedRows = [...prevSelectedRows];
      const selectedIndex = updatedRows.findIndex(
        (selectedRow) => selectedRow.rwaId === row.rwaId
      );

      if (selectedIndex === -1) {
        updatedRows.push(row);
      } else {
        updatedRows.splice(selectedIndex, 1);
      }

      return updatedRows;
    });
  };

  const fetchData = useCallback(
    async (page: number, filters?: any) => {
      try {
        const response: any = await queryFilter(page, pageSize, filters);
        setRwaData(response.data?.items);
        setTotalCount(response.data?.totalCount);
        setCurrentPage(page);
      } catch {
        handleSnackbar("error", "Error fetching data", <ErrorOutlineIcon />);
      }
    },
    [pageSize]
  );

  useEffect(() => {
    fetchData(1); // eslint-disable-next-line
  }, []);

  const handlePaginationChange = (page: number) => {
    fetchData(page, filterData);
  };

  const handleApplyFilter = async () => {
    setFilterData(filterData);
    setOpenModal(false);
    try {
      if (filterData) {
        await fetchData(1, filterData);
        handleSnackbar("success", "Filter applied successfully", <TuneIcon />);
      } else {
        handleSnackbar("error", "No filter selected.", <FilterListOffIcon />);
      }
    } catch (error) {
      handleSnackbar(
        "error",
        "Error fetching filter Data",
        <FilterListOffIcon />
      );
    }
  };

  const fetchSearchResults = async (searchText: string) => {
    try {
      const response: any = await searchAutoComplete({
        societyName: searchText,
      });
      setRwaData(response.data);
      setTotalCount(response.data?.totalCount);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const cities = ["Mumbai", "Bengaluru", "Delhi"];

  const handleClearFilters = () => {
    setFilterData(null);
    fetchData(1, null);
  };

  const handleDownloadSelectedCSV = async () => {
    try {
      if (selectedRows.length > 0) {
        const header = Object.keys(selectedRows[0]);

        const csvContent = [header.join(",")]
          .concat(
            selectedRows.map((row: any) =>
              Object.values(row)
                .map((value: any) => {
                  if (typeof value === "string" && value.includes(",")) {
                    return `"${value.replace(/"/g, '""')}"`;
                  }
                  return value;
                })
                .join(",")
            )
          )
          .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "selected_rows.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        handleSnackbar(
          "success",
          "RWA details downloaded successfully",
          <DownloadTwoToneIcon />
        );
        setSelectedRows([]);
      } else {
        handleSnackbar(
          "error",
          "No rows selected for download",
          <FileDownloadOffTwoToneIcon />
        );
      }
    } catch (error) {
      handleSnackbar(
        "error",
        "Error downloading selected RWA data",
        <FileDownloadOffTwoToneIcon />
      );
    }
  };

  const handleOpenModal = () => {
    setFilterData(filterData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const rwaId: any = event.currentTarget.getAttribute("data-rwaId");
    setAnchorEl(event.currentTarget);
    if (rwaId) {
      setSelectedRwaId(rwaId);
      setRemovedRwaIds((prevIds) => [...prevIds, rwaId]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRemovedRwaIds([]);
  };

  const handleEditRow = (rwaId: string) => {
    const selectedRow = rwaData.find((row) => row.rwaId === rwaId);
    if (selectedRow) {
      setEditRow(selectedRow);
      setEditModal(true);
    }
    handleClose();
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    setEditRow(null);
    fetchData(currentPage, filterData);
  };

  const handleRemove = async () => {
    try {
      if (removedRwaIds) {
        const requestBody = { rwaIds: removedRwaIds };
        await deleteData(requestBody);
        handleClose();
        fetchData(currentPage, filterData);
        handleSnackbar(
          "error",
          "RWA data deleted successfully",
          <DeleteTwoToneIcon />
        );
      } else {
        handleSnackbar(
          "warning",
          "Please try again.",
          <DeleteForeverTwoToneIcon />
        );
      }
    } catch (error) {
      handleSnackbar(
        "error",
        "Error occurred, Please try again.",
        <DeleteForeverTwoToneIcon />
      );
    }
  };

  const handleMultipleRemove = async () => {
    try {
      if (selectedRows && selectedRows.length > 0) {
        const selectedRwaIds = selectedRows.map((row) => row.rwaId);
        const requestBody = { rwaIds: selectedRwaIds };
        await deleteData(requestBody);
        fetchData(currentPage, filterData);
        handleSnackbar(
          "error",
          "Selected rows deleted successfully",
          <DeleteTwoToneIcon />
        );
        setSelectedRows([]);
      } else {
        handleSnackbar(
          "warning",
          "No selected rows, Please try again by selecting the rows you want to delete.",
          <DeleteForeverTwoToneIcon />
        );
      }
    } catch (error) {
      handleSnackbar(
        "error",
        "Error occurred, Please try again.",
        <DeleteForeverTwoToneIcon />
      );
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string, icon: any) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarIcon(icon);
    setSnackbarOpen(true);
  };

  return (
    <Box sx={{ marginTop: "10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              height: "40px",
              marginLeft: "10px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "Search" }}
              value={search}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="outlined"
            sx={{
              color: "",
              backgroundColor: "white",
              border: "1px solid ",
              margin: "0 10px",
              padding: "10px 20px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "",
                color: "white",
                border: "1px solid ",
              },
            }}
            onClick={handleOpenModal}
            endIcon={<TuneIcon />}
          >
            Filter
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "",
              backgroundColor: "white",
              border: "1px solid ",
              margin: "0 10px",
              padding: "10px 20px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "",
                color: "white",
                border: "1px solid ",
              },
            }}
            onClick={handleUploadCsv}
          >
            Upload CSV
          </Button>
          <Box sx={{ marginRight: "10px" }}>
            <CustomSplitButton
              onDownloadAll={handleDownloadCSV}
              onDownloadSelected={handleDownloadSelectedCSV}
              onDeleteSelected={handleMultipleRemove}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ marginBottom: "5%" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Select</StyledTableCell>
              <FirstColumnStyledTableCell>
                Society Name
              </FirstColumnStyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>Number of Flats</StyledTableCell>
              <StyledTableCell
                colSpan={4}
                align="center"
                sx={{ fontWeight: "bold", fontSize: "18px" }}
              >
                Contact Person
              </StyledTableCell>
              <StyledTableCell>Min Price</StyledTableCell>
              <StyledTableCell>Max Price</StyledTableCell>
              <StyledTableCell>Collection Date</StyledTableCell>
              <StyledTableCell>Stage</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rwaData.map((rwaDetails: any) => (
              <TableRow
                key={rwaDetails.rwaId}
                sx={{ backgroundColor: "#FEFDFD" }}
              >
                <StyledTableCell>
                  <Checkbox
                    checked={selectedRows.some(
                      (selectedRow) => selectedRow.rwaId === rwaDetails.rwaId
                    )}
                    onChange={() => handleCheckboxChange(rwaDetails)}
                  />
                </StyledTableCell>
                <FirstColumnStyledTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">
                      {rwaDetails.societyName}
                    </Typography>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      size="small"
                      sx={{ marginLeft: "auto" }}
                      data-rwaId={rwaDetails.rwaId}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          minWidth: "150px",
                          borderRadius: "7px",
                          boxShadow: "none",
                          backgroundColor: "white",
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ padding: "5px 16px" }}
                        onClick={() => handleEditRow(selectedRwaId || "")}
                      >
                        Edit Details
                      </MenuItem>
                      <MenuItem
                        sx={{
                          padding: "5px 16px",
                          color: "red",
                        }}
                        onClick={() => handleRemove()}
                      >
                        Remove
                      </MenuItem>
                    </Menu>
                  </Box>
                </FirstColumnStyledTableCell>
                <StyledTableCell>
                  {rwaDetails.address ? (
                    rwaDetails.address
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.city ? (
                    rwaDetails.city
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.numberOfFlats ? (
                    rwaDetails.numberOfFlats
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.contactPersonName ? (
                    rwaDetails.contactPersonName
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.contactPersonEmail ? (
                    rwaDetails.contactPersonEmail
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.contactPersonPhoneNumber ? (
                    rwaDetails.contactPersonPhoneNumber
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.contactPersonDesignation ? (
                    rwaDetails.contactPersonDesignation
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.minPrice ? (
                    rwaDetails.minPrice
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.maxPrice ? (
                    rwaDetails.maxPrice
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {rwaDetails.collectionDate &&
                  !isNaN(new Date(rwaDetails.collectionDate).getTime()) ? (
                    dateformat(rwaDetails.collectionDate, "dd mmm, yyyy")
                  ) : (
                    <Typography variant="body2">.....</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>{rwaDetails.stage}</Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          bgcolor: "#fff",
          boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.1)",
          py: 1,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <Box
            sx={{
              margin: "auto",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil((totalCount || 0) / pageSize)}
              page={currentPage}
              onChange={(event, page) => handlePaginationChange(page)}
              variant="outlined"
              color="primary"
              boundaryCount={4}
            />
          </Box>
          <Typography sx={{ fontweight: "bold", color: "#0066ff" }}>
            Total count: {totalCount}
          </Typography>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FilterComponent
          filterData={filterData}
          handleFilterChange={handleFilterChange}
          cities={cities}
          handleApplyFilter={handleApplyFilter}
          handleClearFilters={handleClearFilters}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <Modal
        open={editModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditDetails
          row={editRow}
          open={editModal}
          handleClose={handleCloseEditModal}
          handleSnackbar={handleSnackbar}
        />
      </Modal>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
        icon={snackbarIcon}
      />
    </Box>
  );
};

export default RWAConsole;
