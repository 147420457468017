import { BaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function queryFilter(page: number, limit: number, filters?: any) {
  let url = `${BaseUrl}/queryFilter?page=${page}&limit=${limit}`;

  if (filters && Object.keys(filters).length > 0) {
    if (filters.city && filters.city !== "") {
      url += `&city=${filters.city}`;
    }

    if (filters.stage && filters.stage !== "") {
      url += `&stage=${filters.stage}`;
    }

    if (filters.pincode && filters.pincode !== "") {
      url += `&pincode=${filters.pincode}`;
    }

    if (filters.minFlatCount && filters.minFlatCount !== "") {
      url += `&minFlatCount=${filters.minFlatCount}`;
    }

    if (filters.maxFlatCount && filters.maxFlatCount !== "") {
      url += `&maxFlatCount=${filters.maxFlatCount}`;
    }

    if (filters.sortKey && filters.sortKey !== "") {
      url += `&sortKey=${filters.sortKey}`;
    }

    if (filters.order && filters.order !== "") {
      url += `&order=${filters.order}`;
    }

    // if (filters.radius && filters.radius !== "") {
    //   url += `&radius=${filters.radius}`;
    // }

    // if (filters.latitude && filters.latitude !== "") {
    //   url += `&latitude=${filters.latitude}`;
    // }

    // if (filters.longitude && filters.longitude !== "") {
    //   url += `&longitude=${filters.longitude}`;
    // }
  }

  return fetchWrapper(url, {
    method: "GET",
  });
}
