import React, { useCallback, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { steps } from "./vendorConstants";
import BasicInformation from "./BasicInformation";
import BusinessAndFinance from "./BusinessAndFinance";
import BankDetails from "./BankDetails";
import TaxAndCompliance from "./TaxAndCompliance";
import OtherDocuments from "./OtherDocuments";

const VendorRegistrationForm = () => {
  const { vendorId } = useParams<{ vendorId?: string }>();
  const [vendorIdState, setVendorIdState] = useState<string | undefined>(
    vendorId
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  const handleStepChange = useCallback(
    async (step: number) => {
      if (step === steps.length) {
        navigate("/vendors/vendorsList");
        return;
      }
      setActiveStep(step);
    },
    [navigate]
  );

  return (
    <Card
      sx={{
        padding: 3,
        maxWidth: 1200,
        margin: "auto",
        border: "1px solid ",
        marginTop: 3,
        marginBottom: 3,
      }}
    >
      <form>
        <Typography variant="h4" gutterBottom>
          Vendor Registration
        </Typography>
        <Box display="flex" alignItems="center" mb={3}>
          {steps.map((_stepLabel, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor:
                    activeStep === index || activeStep > index
                      ? "#1976d2"
                      : "#ddd",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  color:
                    activeStep === index || activeStep > index
                      ? "white"
                      : "black",
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: "pointer",
                  fontSize: 18,
                  transition: "background-color 0.3s ease",
                }}
              >
                {index + 1}
              </Box>
              {index < steps.length - 1 && (
                <Box
                  sx={{
                    height: 2,
                    backgroundColor:
                      activeStep >= index + 1 ? "#1976d2" : "#ddd",
                    flexGrow: 1,
                    transition: "background-color 0.3s ease",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>

        {steps[activeStep] === "taxAndComplaince" && (
          <TaxAndCompliance
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            vendorId={vendorId || vendorIdState}
            setVendorIdState={setVendorIdState}
          />
        )}
        {steps[activeStep] === "basicInformation" && (
          <BasicInformation
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            vendorId={vendorId ?? vendorIdState}
          />
        )}
        {steps[activeStep] === "businessAndFinance" && (
          <BusinessAndFinance
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            vendorId={vendorId ?? vendorIdState}
          />
        )}
        {steps[activeStep] === "bankDetails" && (
          <BankDetails
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            vendorId={vendorId ?? vendorIdState}
          />
        )}
        {steps[activeStep] === "otherOtherDocuments" && (
          <OtherDocuments
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            vendorId={vendorId ?? vendorIdState}
          />
        )}
      </form>
    </Card>
  );
};

export default VendorRegistrationForm;
