import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SignUpButton = () => {
  const history = useNavigate();

  const handleLogin = () => {
    history("/login");
  };

  const handleSignUp = () => {
    history("/sign-up");
  };

  return (
    <>
      <Button onClick={handleSignUp}>Create Account</Button>
      <Button onClick={handleLogin}>Login</Button>
    </>
  );
};
export default SignUpButton;
