import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Services: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - 90px)`,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFEFC",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box display="flex" flexDirection="row" gap="20px">
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("outdoor")}
        >
          Outdoor
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("airport")}
        >
          Airport
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("airline")}
        >
          Airline
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("radio")}
        >
          Radio
        </Button>
      </Box>
      <Box display="flex" flexDirection="row" marginTop="20px" gap="20px">
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("newspaper")}
        >
          Newspaper
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("digitalMarketing")}
        >
          Digital Marketing
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("influencer")}
        >
          Influencer
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleButtonClick("sports")}
        >
          Sports
        </Button>
      </Box>
    </Box>
  );
};

export default Services;
