import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as XLSX from "xlsx";
import { DigitalMedium } from "../../utils/serviceNames";
import { digitalScreenMapper } from "./DigitalScreenMapper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createMediaDetails } from "../../clients/createMediaDetails";
import { useAuthContext } from "../../providers/AuthProvider";

const DigitalScreenMediaUpload: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [parsedData, setParsedData] = useState<any[]>([]); // Store parsed Excel data
  const [columns, setColumns] = useState<string[]>([]); // Store the column headers
  const [selectedMedium, setSelectedMedium] = useState<string>(""); // Store the selected digital medium
  const [isParsed, setIsParsed] = useState(false); // New state for tracking parse status
  const { authHeader } = useAuthContext();

  // Handle file change
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFile = event.target.files?.[0] || null;

    if (uploadedFile) {
      setIsLoading(true);
      try {
        const excelData = await parseExcelFile(uploadedFile);
        const mappedColumns = digitalScreenMapper(Object.keys(excelData[0])); // Map the column headers
        setParsedData(excelData);
        setColumns(mappedColumns);
        setIsParsed(true); // Mark as parsed after successfully setting data
      } catch (error) {
        console.error("Error parsing Excel:", error);
        alert("Failed to parse the Excel file.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  //   // Handle file upload and parsing
  //   const handleFileUpload = async () => {
  //     if (!file) {
  //       alert("Please upload an Excel file first.");
  //       return;
  //     }

  //     setIsLoading(true);

  //     try {
  //       const excelData = await parseExcelFile(file);
  //       const mappedColumns = digitalScreenMapper(Object.keys(excelData[0])); // Map the column headers
  //       setParsedData(excelData);
  //       setColumns(mappedColumns);
  //     } catch (error) {
  //       console.error("Error parsing Excel:", error);
  //       alert("Failed to parse the Excel file.");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  // Function to parse Excel file and extract data
  const parseExcelFile = async (file: File) => {
    return new Promise<any[]>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryString = event.target?.result;
        const workbook = XLSX.read(binaryString, { type: "binary" });

        // Convert Excel sheet data to JSON format
        const sheetName = workbook.SheetNames[0]; // Get the first sheet name
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet); // Convert to JSON

        resolve(jsonData); // Return parsed JSON data
        setIsParsed(true);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsBinaryString(file); // Read the file as binary
    });
  };

  // Handle dropdown change for the digital medium selection
  const handleMediumChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMedium(event.target.value as string);
    console.log(event.target.value);
  };

  const sendDataToAPI = async (row: any, medium: string) => {
    try {
      if (!authHeader) return;
      const response = await createMediaDetails(
        "digital",
        medium,
        row,
        authHeader
      );

      const responseData = await response.json();
      console.log("Row successfully sent to API:", responseData);
    } catch (error) {
      console.error("Error sending row to API:", error);
    }
  };

  // Function to upload all rows one by one
  const uploadRowsToAPI = async (data: any[], medium: string) => {
    for (const row of data) {
      await sendDataToAPI(row, medium);
    }
  };

  const handleSendData = async () => {
    if (!selectedMedium) {
      alert("Please select a medium type.");
      return;
    }

    await uploadRowsToAPI(parsedData, selectedMedium);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
        xs={12}
      >
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom textAlign={'center'}>
            Excel File Uploader
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            type="file"
            inputProps={{ accept: ".xlsx, .xls" }}
            onChange={handleFileChange}
            variant="outlined"
            fullWidth
            hidden
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth variant="outlined" sx={{ minWidth: 400 }}>
            <InputLabel id="select-medium-label">Select Medium</InputLabel>
            <Select
              value={selectedMedium}
              onChange={(e: any) => handleMediumChange(e)}
              label="Select Medium"
              disabled={!isParsed}
            >
              {DigitalMedium.digital.map((medium) => (
                <MenuItem key={medium} value={medium}>
                  {medium.charAt(0).toUpperCase() + medium.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={handleSendData}
            disabled={!isParsed || !selectedMedium}
            style={{ marginLeft: "16px" }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Send Data"}
          </Button>
        </Grid>

        {parsedData.length > 0 && columns.length > 0 && (
          <TableContainer
            sx={{ overflow: "auto", px: 2, mt: 8 }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        backgroundColor: "#1976D2",
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {parsedData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex}>
                        {row[column] || "N/A"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </>
  );
};

export default DigitalScreenMediaUpload;
