import { fetchWrapper } from "./fetchWrapper";
import { BaseUrl } from "./baseUrls";

export async function deleteData(formValues: any) {
  return fetchWrapper(`${BaseUrl}/deleteData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: formValues,
  });
}
