import { Button, Container, Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import PageTitleComponent from "../PageTitleComponent";

const ReviewFormat: React.FC = () => {
	const location = useLocation();
	const { datasetName } = location.state || {};

	const getColumnNames = (datasetName: string): string[] => {
		switch (datasetName) {
			case "baseData":
				return [
					"societyName",
					"address",
					"pincode",
					"city",
					"latitude",
					"longitude",
					"numberOfFlats",
					"uploadDate",
					"uploadedBy",
					"googleMapUrl",
				];
			case "extendedData":
				return [
					"societyName",
					"address",
					"pincode",
					"city",
					"latitude",
					"longitude",
					"numberOfFlats",
					"contactPersonName",
					"contactPersonPhoneNumber",
					"contactPersonEmail",
					"contactPersonDesignation",
					"pictureProof",
					"locationProof",
					"minPrice",
					"maxPrice",
					"comments",
					"collectionDate",
					"collectedBy",
					"uploadDate",
					"uploadedBy",
					"googleMapUrl",
				];
			case "finalizedData":
				return [
					"societyName",
					"address",
					"pincode",
					"city",
					"latitude",
					"longitude",
					"numberOfFlats",
					"contactPersonName",
					"contactPersonPhoneNumber",
					"contactPersonEmail",
					"contactPersonDesignation",
					"pictureProof",
					"locationProof",
					"minPrice",
					"maxPrice",
					"comments",
					"collectionDate",
					"collectedBy",
					"review",
					"reviewDate",
					"remarks",
					"reviewedBy",
					"uploadDate",
					"uploadedBy",
					"googleMapUrl",
				];
			default:
				return ["Column1", "Column2", "Column3"];
		}
	};

	const columnNames = getColumnNames(datasetName);
	const fileName = `${datasetName}_file.csv`;

	const handleDownload = () => {
		const csvHeader = columnNames.join(",");
		const csvContent = csvHeader + "\n";
		const blob = new Blob([csvContent], { type: "text/csv" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	return (
		<Container>
			<PageTitleComponent title='Data Set Preview' subTitle={datasetName} />
			<Button onClick={handleDownload}>Download</Button>
			<Box
				sx={{
					overflowX: "auto",
					maxHeight: "300px",
					maxWidth: "80vw",
				}}
			>
				<table
					style={{
						borderCollapse: "collapse",
						width: "auto",
						minWidth: "100%",
					}}
				>
					<tbody>
						<tr>
							{columnNames.map((columnName, columnIndex) => (
								<td
									key={columnIndex}
									style={{ border: "1px solid #ccc", padding: "8px" }}
								>
									{columnName}
								</td>
							))}
						</tr>
					</tbody>
					<tbody>
						{[1, 2, 3].map((_, rowIndex) => (
							<tr key={rowIndex}>
								{columnNames.map((_, columnIndex) => (
									<td
										key={columnIndex}
										style={{ border: "1px solid #ccc", padding: "8px" }}
									></td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</Box>
		</Container>
	);
};

export default ReviewFormat;
