import {
  TextField,
  Typography,
  Button,
  Grid,
  Checkbox,
  IconButton,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import "./SignUpPageCSS.css";
import initialSignUpValues, { SignUpValues } from "./SignUpValues";
import { ChangeEvent, useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { createUsers } from "../../clients/createUsers";
import { useAuthContext } from "../../providers/AuthProvider";

const SignUpComponent = () => {
  const [values, setValues] = useState<SignUpValues>(initialSignUpValues);
  const [errors, setErrors] = useState<any>({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const history = useNavigate();
  const [checkboxChecked, setCheckboxChecked] = useState(false); // State for checkbox
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const { authHeader } = useAuthContext();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "password") {
      const isPasswordValid = validatePassword(value);
      setIsPasswordValid(isPasswordValid);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        password: isPasswordValid
          ? ""
          : "Password must contain at least one uppercase letter , one digit and minimum length 8 characters.",
      }));
    }
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return passwordRegex.test(password);
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); // Toggle showPassword state
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!isPasswordValid) return;
    const emailValid = validateEmail(values.emailId);
    let newErrors = { ...errors };

    if (!values.firstName) {
      newErrors = { ...newErrors, firstName: "First Name required" };
    }
    if (!values.lastName) {
      newErrors = { ...newErrors, lastName: "Last Name is required" };
    }

    if (!checkboxChecked) {
      newErrors = { ...newErrors, checkbox: "Please agree to the terms" };
    } else {
      newErrors.checkbox = "";
    }

    if (!values.emailId) {
      newErrors = { ...newErrors, email: "EmailId or Username is required" };
    }
    if (!values.password) {
      newErrors = { ...newErrors, password: "Password is required" };
    }
    if (!emailValid) {
      newErrors = { ...newErrors, email: "Invalid email format" };
    } else {
      newErrors.email = "";
    }

    setErrors(newErrors);

    if (
      !values.firstName ||
      !values.lastName ||
      !checkboxChecked ||
      !emailValid ||
      !values.emailId ||
      !values.password
    ) {
      return;
    }

    setLoading(true);
    try {
      if (authHeader) {
        await createUsers(values, authHeader);
        setLoading(false);
        history("/go-to-email-inbox", {
          state: { firstName: values.firstName, emailId: values.emailId },
        });
      }
    } catch (error: any) {
      console.error("Error:", error);
      setLoading(false);
      if (error.status === 400) {
        setDialogMessage("Account already exists. Please login.");
        setDialogOpen(true);
      }
    }
  };

  const redirectToLoginPage = () => {
    history("/login");
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="5px">
      <Typography
        variant="h3"
        sx={{ display: "flex", justifyContent: "center" }}
        gutterBottom
      >
        Sign Up
      </Typography>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        GMG Console
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            size="small"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            placeholder="First Name"
            sx={{
              mt: 2,
            }}
            InputProps={{
              className: "textField-input",
            }}
            error={errors.firstName}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "red",
              marginLeft: "12px",
            }}
          >
            {errors.firstName && (
              <>
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {errors.firstName}
                </span>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            size="small"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            sx={{
              mt: 2,
            }}
            InputProps={{
              className: "textField-input",
            }}
            error={errors.lastName}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "red",
              marginLeft: "12px",
            }}
          >
            {errors.lastName && (
              <>
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {errors.lastName}
                </span>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={6} md={12}>
          <TextField
            fullWidth
            name="emailId"
            size="small"
            value={values.emailId}
            onChange={handleChange}
            placeholder="Email"
            sx={{
              mt: 2,
            }}
            InputProps={{
              className: "textField-input",
            }}
            error={!!errors.email}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "red",
              marginLeft: "12px",
            }}
          >
            {errors.email && (
              <>
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {errors.email}
                </span>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={6} md={12}>
          <TextField
            type={showPassword ? "text" : "password"}
            size="small"
            fullWidth
            name="password"
            value={values.password}
            onChange={handleChange}
            placeholder="Password"
            sx={{
              mt: 2,
            }}
            InputProps={{
              className: "textField-input",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "red",
              marginLeft: "12px",
            }}
          >
            {errors.password && (
              <>
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {errors.password}
                </span>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={6} md={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={checkboxChecked}
              onChange={handleCheckboxChange}
              sx={{ mr: "4px", fontSize: "8px" }}
            />
            <Typography sx={{ fontSize: "12px" }}>
              Yes, I understand and agree to the{" "}
              <span style={{ color: "blue" }}>GMG Terms of Service</span>,
              including the{" "}
              <span style={{ color: "blue" }}>User Agreement</span> and{" "}
              <span style={{ color: "blue" }}>Privacy Policy</span>.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "12px",
            }}
          >
            {errors.checkbox && (
              <>
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span
                  style={{ marginLeft: "12px", color: "red", fontSize: "14px" }}
                >
                  {errors.checkbox}
                </span>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        onClick={handleSubmit}
        disabled={!checkboxChecked}
      >
        {loading ? (
          <CircularProgress sx={{ color: "white" }} size={24} />
        ) : (
          "CREATE AN ACCOUNT"
        )}
      </Button>
      <Typography sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        Already have an account?{"  "}
        <span
          style={{
            cursor: "pointer",
            marginLeft: "5px",
            textTransform: "none",
            color: "",
            fontWeight: "600",
          }}
          onClick={redirectToLoginPage}
        >
          Log in
        </span>
      </Typography>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <p>{dialogMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={redirectToLoginPage}>Go to Login page</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SignUpComponent;
