export const digitalScreenMapper = (columns: string[]): string[] => {
    const mappings: { [key: string]: string } = {
        zone: "zone",
        locality: "locality",
        propertyName: "propertyName",
        pinCode: "pinCode",
        propertyPriceUpto: "propertyPriceUpto",
        screenSize: "screenSize",
        noOfTowers: "noOfTowers",
        noOfScreens: "noOfScreens",
        captiveHouseholds: "captiveHouseholds",
        approxReach: "approxReach",
        noOfImpressionsPerMonth: "noOfImpressionsPerMonth",
        locationMapLink: "locationMapLink",
        latitude: "latitude",
        longitude: "longitude",
        facilityType: "facilityType",
        tenantDetails: "tenantDetails",
        nccsClass: "nccsClass",
        mediaSiteId: "mediaSiteId"
    };

    return columns.map((column) => mappings[column] || column);
};
