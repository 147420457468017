import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Container,
  TableHead,
} from "@mui/material";

interface TableComponentProps {
  tableData: any[];
}

const TableComponent: React.FC<TableComponentProps> = ({ tableData }) => {
  
  const renderHeader = () => {
    if (!tableData || tableData.length === 0) return null;
    const fields = Object.keys(tableData[0]);
    const getHeaderLabel = (field: string) => {
      const lowerCaseField = field.toLowerCase();
      if (lowerCaseField.includes("vehicle number")) {
        return "Vehicle Number";
      } else if (lowerCaseField.includes("image")) {
        return "Image";
      }
      return field;
    };
    return (
      <TableHead>
        <TableRow>
          {fields.map((field) => (
            <TableCell
              sx={{ textTransform: "capitalize", fontSize: "18px" }}
              key={field}
            >
              {getHeaderLabel(field)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Container sx={{ margin: "20px auto" }}>
      <TableContainer component={Paper}>
        <Table>
          {renderHeader()}
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {Object.values(row).map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {typeof cell === "object"
                      ? JSON.stringify(cell)
                      : (cell as React.ReactNode)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableComponent;
