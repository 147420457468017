import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  SelectChangeEvent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ImageOutlined as ImageIcon,
  InfoOutlined as InfoIcon,
} from "@mui/icons-material";
import { getMediaDetailsByService } from "../../clients/getMediaDetailsByService";
import { useNavigate, useLocation } from "react-router-dom";
import { getUploadedImages } from "../../clients/getUploadedImages";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { attachImageUrlToMediaData } from "../../clients/attachImageUrlToMediaData";
import { removeImageUrlFromMediaData } from "../../clients/removeImageUrlFromMediaData";
import { updateMediaDetails } from "../../clients/updateMediaDetails";
import { getMediaDataById } from "../../clients/getMediaDataById";
import ViewModal from "./ViewModal";
import ImageModal from "./ImageModal";
import { useAuthContext } from "../../providers/AuthProvider";

const StyledCard = styled(Card)({
  width: "99%",
  marginBottom: "10px",
  border: "1px solid blue",
  marginLeft: "7px",
});

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const LeftDetailsBox = styled(Box)({
  flex: "0 0 37%",
  paddingRight: "10px",
});

const RightDetailsBox = styled(Box)({
  flex: "0 0 20%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
});

const ImageBox = styled(Box)({
  flex: "0 0 33%",
  height: "230px",
});

const FallbackTextBox = styled(Box)({
  flex: "0 0 20%",
  paddingRight: "16px",
  border: "1px dashed ",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
});

const StyledText = styled(Typography)({
  margin: "auto",
});

export interface ApiResponseItem {
  gmgAssetCode: string;
  medium: string;
  type: string | null;
  areaInSqFeet: number | null;
  landmark: string | null;
  location: string | null;
  latitude: number | null;
  longitude: number | null;
  city: string | null;
  area: string | null;
  state: string | null;
  pincode: number | null;
  displayCostPerMonth: number | null;
  vendorName: string | null;
  vendorAssetCode: string | null;
  mountingPrice: number | null;
  printingCharge: number | null;
  towards: string | null;
  width: number | null;
  height: number | null;
  imageUrl: string | null;
  createdAt: string;
  updatedAt: string;
  visitCount: number;
  createdBy: string;
  updatedBy: string;
}

export interface FileAsset {
  assetId: string;
  fileUrl: string;
  metadata: string;
  contentType: string;
  tags: string;
  createdBy: string | null;
  updatedBy: string | null;
}

const Outdoor: React.FC = () => {
  const { authHeader } = useAuthContext();
  const service = "outdoor";
  const [data, setData] = useState<ApiResponseItem[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<ApiResponseItem | null>(null);
  const [modalImages, setModalImages] = useState<FileAsset[]>([]);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedCardDetails, setSelectedCardDetails] =
    useState<ApiResponseItem | null>(null);

  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditable, setIsEditable] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [changes, setChanges] = useState({});
  const hasFetchedData = useRef(false);

  const mediaData = location.state?.mediaData;

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortKey, setSortKey] = useState<string>();

  const fetchData = useCallback(
    async (offset: number) => {
      setIsLoading(true);
      try {
        const response: any = sortKey
          ? await getMediaDetailsByService(service, offset, sortKey, sortOrder)
          : await getMediaDetailsByService(service, offset);
        const result: ApiResponseItem[] = response.data.data;
        setData((prevData) => [...prevData, ...result]);
        setNextOffset(response.data.nextOffset);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [service, sortKey, sortOrder]
  );

  const handleOpenModal = useCallback(async (cardDetails: ApiResponseItem) => {
    setOpen(true);
    setIsLoadingModal(true);
    try {
      const response: any = await getUploadedImages();
      setModalImages(response.data);
      setModalData(cardDetails);
    } catch (error) {
      console.error("Error fetching additional data:", error);
    } finally {
      setIsLoadingModal(false);
    }
  }, []);

  const handleSortChange = useCallback((e: SelectChangeEvent) => {
    const { value } = e.target;
    console.log({ value });
    if (value === "") {
      setSortKey((prev) => undefined);
    }
    setSortKey((prev) => value.split("-")[0]);
    setSortOrder((prev) => (value.split("-")[1] === "asc" ? "asc" : "desc"));
    setData([]);
    setOffset(0);
    hasFetchedData.current = false;
  }, []);

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchData(offset);
      hasFetchedData.current = true;
    }
    if (mediaData) {
      handleOpenModal(mediaData);
      navigate(location.pathname, { replace: true });
    }
  }, [
    offset,
    location.pathname,
    mediaData,
    navigate,
    fetchData,
    handleOpenModal,
    sortKey,
    sortOrder,
  ]);

  const handleViewDetails = useCallback(
    async (gmgAssetCode: string) => {
      try {
        const response: any = await getMediaDataById(service, gmgAssetCode);
        const data = response.data;
        setSelectedCardDetails({ ...data, gmgAssetCode });
        setViewModalOpen(true);
      } catch (e) {
        console.error(e);
      }
    },
    [service]
  );

  const handleSave = useCallback(async () => {
    try {
      if (!selectedImage) {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Please select an Image");
        }, 100);
        return;
      }

      if (!modalData || !modalData.gmgAssetCode) {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Media data error");
        }, 100);
        return;
      }

      const response = await attachImageUrlToMediaData(
        service,
        modalData.gmgAssetCode,
        selectedImage
      );

      if (response.data) {
        setData((prevData) =>
          prevData.map((item) =>
            item.gmgAssetCode === modalData?.gmgAssetCode
              ? { ...item, imageUrl: selectedImage }
              : item
          )
        );
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("success", "Image saved successfully");
        }, 100);
      } else {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Failed to attach Image");
        }, 100);
      }
      handleCloseModal();
    } catch (e) {
      handleSnackbar("error", "Error attaching Image");
      console.error("Error attaching Image", e);
    }
  }, [modalData, selectedImage, snackbarOpen, service]);

  const handleRemoveImage = async () => {
    try {
      if (!modalData || !modalData.gmgAssetCode) {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Media data error");
        }, 100);
        return;
      }

      const response = await removeImageUrlFromMediaData(
        service,
        modalData.gmgAssetCode
      );

      if (response.data) {
        setData((prevData) =>
          prevData.map((item) =>
            item.gmgAssetCode === modalData?.gmgAssetCode
              ? { ...item, imageUrl: null }
              : item
          )
        );
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("success", "Image removed successfully");
        }, 100);
      } else {
        if (snackbarOpen) setSnackbarOpen(false);
        setTimeout(() => {
          handleSnackbar("error", "Failed to remove image");
        }, 100);
      }
      handleCloseModal();
    } catch (e) {
      handleSnackbar("error", "Error removing attached Image");
      console.error("Error removing attached Image", e);
    }
  };

  const handleAddImage = () => {
    navigate("/upload/fileUpload", {
      state: { modalData, location: location.pathname },
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    setModalData(null);
    setModalImages([]);
    setSelectedImage(null);
    setSearchText("");
  };

  const handleViewModalClose = () => {
    setViewModalOpen(false);
    setIsEditable(false);
  };

  const handleLoadMore = () => {
    if (nextOffset !== null) {
      hasFetchedData.current = false;
      setOffset(nextOffset);
    }
  };

  const handleImageSelect = (fileUrl: string) => {
    if (selectedImage === fileUrl) {
      setSelectedImage(null);
    } else {
      setSelectedImage(fileUrl);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredImages = modalImages.filter((modalImage) => {
    if (modalImage.metadata)
      return modalImage.metadata
        .toLowerCase()
        .includes(searchText.toLowerCase());
    else return null;
  });

  const formatCurrency = (value: number | null) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value || 0);
  };

  const handleInputChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedCardDetails) {
        setSelectedCardDetails({
          ...selectedCardDetails,
          [field]: event.target.value,
        });
        setIsDirty(true);
      }
      setChanges({ ...changes, [field]: event.target.value });
    };

  const handleSelectChange =
    (field: string) => (event: SelectChangeEvent<string>) => {
      if (selectedCardDetails) {
        setSelectedCardDetails({
          ...selectedCardDetails,
          [field]: event.target.value,
        });
        setIsDirty(true);
      }
      setChanges({ ...changes, [field]: event.target.value });
    };

  const handleSaveForm = useCallback(async () => {
    if (!isDirty) {
      handleSnackbar("warning", "No changes made");
      return;
    }

    try {
      if (authHeader) {
        const response: any =
          selectedCardDetails?.medium && selectedCardDetails?.gmgAssetCode
            ? await updateMediaDetails(
                service,
                selectedCardDetails.medium,
                String(selectedCardDetails.gmgAssetCode),
                changes,
                authHeader
              )
            : handleSnackbar("error", "Error in updating details");
        if (response) {
          handleSnackbar("success", "Details updated successfully");
        }
      }
    } catch (e) {
      console.error(e);
      handleSnackbar("error", "Error in updating details");
    }

    setIsEditable(false);
    setChanges({});
  }, [selectedCardDetails, service, changes, isDirty, authHeader]);

  const handleSnackbar = (
    severity: "success" | "error" | "warning" | "info",
    message: string
  ) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const formatDate = (value: string) => {
    if (!value || value === "") return;
    const date = new Date(Date.parse(value));
    const modifyDate = String(date).split(" ").splice(0, 5).join(" ");
    return modifyDate;
  };

  return (
    <Box p={2}>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom sx={{ marginLeft: "1%" }}>
          Outdoor Media
        </Typography>
        <FormControl sx={{ width: "200px" }}>
          <InputLabel>Sort By</InputLabel>
          <Select label="Sort By" onChange={handleSortChange}>
            <MenuItem value="createdAt-asc">Earliest Created</MenuItem>
            <MenuItem value="createdAt-desc">Latest Created</MenuItem>
            <MenuItem value="updatedAt-asc">Earliest Updated</MenuItem>
            <MenuItem value="updatedAt-desc">Latest Updated</MenuItem>
            <MenuItem value="">Clear All</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} key={item.gmgAssetCode}>
            <StyledCard>
              <StyledCardContent>
                <ImageBox>
                  {item.imageUrl ? (
                    <img
                      src={item.imageUrl}
                      alt="Media"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <FallbackTextBox>
                      <StyledText variant="body2">
                        Image not available
                      </StyledText>
                    </FallbackTextBox>
                  )}
                </ImageBox>
                <LeftDetailsBox>
                  <Typography variant="h6" gutterBottom>
                    <strong>
                      GMG ASSET CODE: <br />
                    </strong>
                    {item.gmgAssetCode}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>MEDIUM: </strong>
                    {item.medium}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>LOCATION: </strong>
                    {item.location}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>LANDMARK: </strong> {item.landmark}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>CITY: </strong>
                    {item.city}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>DISPLAY COST PER MONTH: </strong>
                    {item.displayCostPerMonth || "N/A"}
                  </Typography>
                </LeftDetailsBox>
                <RightDetailsBox>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<InfoIcon />}
                    onClick={() => handleViewDetails(item.gmgAssetCode)}
                  >
                    View
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<ImageIcon />}
                    onClick={() => handleOpenModal(item)}
                  >
                    Image
                  </Button>
                </RightDetailsBox>
              </StyledCardContent>
              <Box padding="0 0 16px 16px">
                <Grid container spacing={2}>
                  <Grid item md={4.3}>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Uploaded By : </strong>
                      {item.createdBy}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      <strong>Uploaded On : </strong>
                      {String(formatDate(item.createdAt))}
                    </Typography>
                  </Grid>
                  <Grid item md={4.5}>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Updated By : </strong>
                      {item.updatedBy}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      <strong>Updated On : </strong>
                      {String(formatDate(item.updatedAt))}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={2}>
        {nextOffset && (
          <Button
            variant="contained"
            onClick={handleLoadMore}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Load More"}
          </Button>
        )}
      </Box>
      <ImageModal
        open={open}
        handleCloseModal={handleCloseModal}
        isLoadingModal={isLoadingModal}
        modalData={modalData}
        searchText={searchText}
        handleSearchChange={handleSearchChange}
        handleAddImage={handleAddImage}
        filteredImages={filteredImages}
        selectedImage={selectedImage}
        handleImageSelect={handleImageSelect}
        handleSave={handleSave}
        handleRemoveImage={handleRemoveImage}
      />
      <ViewModal
        service={service}
        viewModalOpen={viewModalOpen}
        handleViewModalClose={handleViewModalClose}
        selectedCardDetails={selectedCardDetails}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        formatCurrency={formatCurrency}
        handleSaveForm={handleSaveForm}
      />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Outdoor;
