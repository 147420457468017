import React from "react";
import { Modal, Box, Typography, TextField, Button, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FileAsset } from "./Outdoor";

interface ImageModalProps {
  open: boolean;
  handleCloseModal: () => void;
  isLoadingModal: boolean;
  modalData: any;
  searchText: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddImage: () => void;
  filteredImages: FileAsset[];
  selectedImage: string | null;
  handleImageSelect: (fileUrl: string) => void;
  handleSave: () => Promise<void>;
  handleRemoveImage: () => Promise<void>;
}

const ImageModal: React.FC<ImageModalProps> = ({
  open,
  handleCloseModal,
  isLoadingModal,
  modalData,
  searchText,
  handleSearchChange,
  handleAddImage,
  filteredImages,
  selectedImage,
  handleImageSelect,
  handleSave,
  handleRemoveImage,
}) => {
  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box
        sx={{
          width: "80%",
          height: "90%",
          border: `3px solid `,
          backgroundColor: "white",
          margin: "auto",
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          gap: "40px",
        }}
      >
        {isLoadingModal ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="h5">Media Details</Typography>
                <Typography paddingLeft="10px">ID: {modalData?.id}</Typography>
                <Typography paddingLeft="10px">
                  Medium: {modalData?.medium}
                </Typography>
                <Typography paddingLeft="10px">
                  Location: {modalData?.location}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" gap="20px">
                <TextField
                  sx={{ width: "400px" }}
                  variant="outlined"
                  placeholder="Search Metadata"
                  value={searchText}
                  onChange={handleSearchChange}
                />
                <Button variant="outlined" onClick={handleAddImage}>
                  Add Image
                </Button>
              </Box>
            </Box>
            <Box
              maxHeight="65%"
              overflow="auto"
              flexGrow={1}
              flexDirection="row"
              paddingBottom="16px"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f8f8f8",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#ff9900",
                },
              }}
            >
              <Typography variant="h5">Images</Typography>
              <Grid container spacing={4} padding="10px" paddingBottom="22px">
                {filteredImages.map(
                  (modalImage: { fileUrl: string }, index: number) => (
                    <Grid item xs={3} key={index}>
                      <Box
                        sx={{
                          position: "relative",
                          paddingTop: "65%",
                          border: `3px solid `,
                          borderRadius: "4px",
                          overflow: "hidden",
                          cursor: "pointer",
                          "&:hover": {
                            borderColor: "green",
                          },
                          ...(selectedImage === modalImage.fileUrl && {
                            borderColor: "green",
                            borderWidth: "3px",
                          }),
                        }}
                        onClick={() => handleImageSelect(modalImage.fileUrl)}
                      >
                        <img
                          src={modalImage.fileUrl}
                          alt={`Media ${index}`}
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        {selectedImage === modalImage.fileUrl && (
                          <CheckCircleIcon
                            sx={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              color: "green",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              marginTop="16px"
              sx={{ marginBottom: "10px" }}
              gap="200px"
            >
              <Button
                size="large"
                variant="outlined"
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <Button size="large" variant="contained" onClick={handleSave}>
                Save
              </Button>
              {modalData && modalData.imageUrl && (
                <Button
                  size="large"
                  onClick={handleRemoveImage}
                  variant="outlined"
                >
                  Remove
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ImageModal;
