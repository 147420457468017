import { LoginValues } from "../providers/types";
import { AuthApiBaseUrl, clientId } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function login({
  emailId,
  password,
  accessToken,
}: LoginValues): Promise<{ token: string }> {
  const url = `${AuthApiBaseUrl}/login?clientId=${clientId}`;

  const res = await fetchWrapper(url, {
    method: "POST",
    body: {
      emailId,
      password,
    },
    headers: {
      Authorization: accessToken,
    },
  });
  return res.data as { token: string };
}
