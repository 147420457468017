import React from "react";
import {
  Box,
  Button,
  Typography,
  CardContent,
  Card,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import TableChartIcon from "@mui/icons-material/TableChart";

const cardData = [
  {
    title: "Image File Upload",
    description:
      "Upload your images with tags and metadata. Organize and categorize your image library for better management and retrieval.",
    icon: <ImageIcon sx={{ fontSize: 90, color: "#1976d2" }} />,
    path: "/fileUpload",
  },
  {
    title: "Media Data Form",
    description:
      "Fill out a detailed form to provide metadata for a single media item. Ensure all relevant details are accurately captured for future reference.",
    icon: <DescriptionIcon sx={{ fontSize: 90, color: "#1976d2" }} />,
    path: "/mediaDataForm",
  },
  {
    title: "Media Data File",
    description:
      "Upload Excel sheets or CSV files for bulk media data entries. Streamline the process of adding multiple media records at once.",
    icon: <TableChartIcon sx={{ fontSize: 90, color: "#1976d2" }} />,
    path: "/mediaDataFile",
  },
];

const UploadMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPage = (path: string) => {
    navigate(`${location.pathname}${path}`);
  };

  return (
    <Box
      sx={{
        height: `80vh`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFEFC",
        padding: "20px",
      }}
    >
      <Grid container spacing={-80}>
        {cardData.map((card, index) => (
          <Grid item md={4} key={index}>
            <Card
              sx={{
                maxWidth: 345,
                margin: "auto",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                {card.icon}
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {card.description}
                </Typography>
              </CardContent>
              <Button
                size="large"
                fullWidth
                variant="contained"
                onClick={() => navigateToPage(card.path)}
              >
                Go to {card.title}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UploadMenu;
