import { BaseUrl } from "./baseUrls";
import { fetchWrapper } from "./fetchWrapper";

export async function searchAutoComplete(formData: any) {
  const url = `${BaseUrl}/searchAutoComplete`;
  return fetchWrapper(url, {
    method: "POST",
    body: formData,
  });
}
