import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  SelectChangeEvent,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Mediums, ServiceMediums } from "../../utils/serviceNames";
import theme from "../../theme";

interface ViewModalProps {
  service: string;
  viewModalOpen: boolean;
  handleViewModalClose: () => void;
  selectedCardDetails: any;
  isEditable: boolean;
  setIsEditable: (editable: boolean | ((prev: boolean) => boolean)) => void;
  handleSelectChange: (
    field: string
  ) => (event: SelectChangeEvent<string>) => void;
  handleInputChange: (
    field: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  formatCurrency: (value: number) => string;
  handleSaveForm: () => void;
}

const ViewModal: React.FC<ViewModalProps> = ({
  service,
  viewModalOpen,
  handleViewModalClose,
  selectedCardDetails,
  isEditable,
  setIsEditable,
  handleSelectChange,
  handleInputChange,
  formatCurrency,
  handleSaveForm,
}) => {
  const [showEditBanner, setShowEditBanner] = useState(false);

  const handleEditClick = () => {
    if (isEditable) {
      setIsEditable(false);
      setShowEditBanner(false);
    } else {
      setIsEditable(true);
      setShowEditBanner(true);
    }
  };

  return (
    <Modal open={viewModalOpen} onClose={handleViewModalClose}>
      <Box
        sx={{
          width: "80%",
          height: "90%",
          border: `3px solid `,
          backgroundColor: "white",
          margin: "auto",
          marginTop: "3%",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f8f8f8",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#ff9900",
          },
          boxSizing: "border-box",
          padding: "16px 18px",
        }}
      >
        {showEditBanner && (
          <Alert
            severity="info"
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.getContrastText(theme.palette.primary.light),
            }}
          >
            Edit mode is enabled.
          </Alert>
        )}
        {selectedCardDetails && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                GMG ASSET CODE: {selectedCardDetails.gmgAssetCode}
              </Typography>
              <Button
                variant="outlined"
                startIcon={isEditable ? undefined : <EditIcon />}
                onClick={handleEditClick}
              >
                {isEditable ? "Cancel Edit" : "Edit"}
              </Button>
            </Box>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="media-type-label">Medium</InputLabel>
                    <Select
                      labelId="media-type-label"
                      label="Medium"
                      value={selectedCardDetails.medium}
                      onChange={handleSelectChange("medium")}
                      disabled={!isEditable}
                    >
                      {service &&
                        ServiceMediums[
                          service as keyof typeof ServiceMediums
                        ]?.map((medium: string, index: number) => (
                          <MenuItem key={index} value={medium}>
                            {Mediums[
                              medium as keyof typeof Mediums
                            ].toUpperCase()}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      labelId="type-label"
                      label="Type"
                      value={selectedCardDetails.type}
                      onChange={handleSelectChange("type")}
                      disabled={!isEditable}
                    >
                      <MenuItem value="lit">Lit</MenuItem>
                      <MenuItem value="backLit">Back Lit</MenuItem>
                      <MenuItem value="frontLit">Front Lit</MenuItem>
                      <MenuItem value="led">LED</MenuItem>
                      <MenuItem value="nonLit">Non Lit</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Width"
                    value={selectedCardDetails.width}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("width")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Height"
                    value={selectedCardDetails.height}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("height")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Total Sqft"
                    value={selectedCardDetails.areaInSqFeet}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("areaInSqFeet")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="State"
                    value={selectedCardDetails.state}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("state")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="City"
                    value={selectedCardDetails.city}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("city")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Area"
                    value={selectedCardDetails.area}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("area")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Location"
                    value={selectedCardDetails.location}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("location")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Towards"
                    value={selectedCardDetails.towards}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("towards")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Landmark"
                    value={selectedCardDetails.landmark}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("landmark")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Pincode"
                    value={selectedCardDetails.pincode}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("pincode")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Longitude"
                    value={selectedCardDetails.longitude}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("longitude")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Latitude"
                    value={selectedCardDetails.latitude}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("latitude")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Display Cost per Month"
                    value={formatCurrency(
                      selectedCardDetails.displayCostPerMonth
                    )}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("displayCostPerMonth")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Mounting Price"
                    value={formatCurrency(selectedCardDetails.mountingPrice)}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("mountingPrice")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Printing Charge"
                    value={formatCurrency(selectedCardDetails.printingCharge)}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("printingCharge")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Vendor Asset Code"
                    value={selectedCardDetails.vendorAssetCode}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("vendorAssetCode")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Vendor Id"
                    value={selectedCardDetails.vendorId}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("vendorId")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Vendor Name"
                    value={selectedCardDetails.vendorName}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("vendorName")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Remarks"
                    value={selectedCardDetails.remarks}
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange("remarks")}
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    disabled={!isEditable}
                  />
                </Grid>
                {selectedCardDetails.additionalDetails && 
                Object.keys(
                  JSON.parse(selectedCardDetails.additionalDetails || "{}")
                ).map((key) => {
                  const label = key
                    .split(" ")
                    .map((word) => {
                      return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                      );
                    })
                    .join(" ");
                  return (
                    <Grid item xs={12} sm={6} key={key}>
                      <TextField
                        label={label}
                        value={
                          JSON.parse(selectedCardDetails.additionalDetails)[key]
                        }
                        fullWidth
                        margin="normal"
                        onChange={handleInputChange(key)}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                        disabled={!isEditable}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </form>
            <Box display="flex" justifyContent="center" mt={2} gap="100px">
              {isEditable && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleSaveForm();
                    setShowEditBanner(false);
                  }}
                  size="large"
                >
                  Save
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => {
                  handleViewModalClose();
                  setShowEditBanner(false);
                }}
                size="large"
              >
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ViewModal;
