import React, { useState, ChangeEvent } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { updateData } from "../../clients/updateData";
import EditNoteIcon from '@mui/icons-material/EditNote';

interface Props {
  row: any;
  open: boolean;
  handleClose: () => void;
  handleSnackbar: (severity: "success" | "error" | "warning" | "info", message: string, icon: React.ReactNode) => void;
}

const EditDetails: React.FC<Props> = ({ row, open, handleClose, handleSnackbar }) => {
  const [editedRow, setEditedRow] = useState<any>(row || {});

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedRow((prevRow: any) => ({
      ...prevRow,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await updateData(editedRow, editedRow.rwaId);
      handleClose();
      handleSnackbar("success", "RWA details updated successfully", <EditNoteIcon/>);
    } catch (error) {
      handleSnackbar("error", "Failed to update RWA details. Try again", <EditNoteIcon/>)
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: "15px 10px 10px 10px",
        display: "flex",
        flexDirection: "column",
        minWidth: "60%",
        minHeight: "60%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
          Apply Filters
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid item container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            name="societyName"
            label="Society Name"
            value={editedRow.societyName || ""}
            onChange={handleChange}
            fullWidth
            sx={{ margin: "5px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="address"
            label="Address"
            value={editedRow.address || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="city"
            label="City"
            value={editedRow.city || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="pincode"
            label="Pincode"
            value={editedRow.pincode || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="numberOfFlats"
            label="Number Of Flats"
            value={editedRow.numberOfFlats || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="minPrice"
            label="Min Price"
            value={editedRow.minPrice || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="maxPrice"
            label="Max Price"
            value={editedRow.maxPrice || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="stage"
            label="Stage"
            value={editedRow.stage || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            name="comments"
            label="Comments"
            value={editedRow.comments || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Contact Person Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="contactPersonName"
            label="Contact Person Name"
            value={editedRow.contactPersonName || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="contactPersonPhoneNumber"
            label="Contact Person PhoneNumber"
            value={editedRow.contactPersonPhoneNumber || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="contactPersonEmail"
            label="Contact Person Email"
            value={editedRow.contactPersonEmail || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="contactPersonDesignation"
            label="Contact Person Designation"
            value={editedRow.contactPersonDesignation || ""}
            onChange={handleChange}
            fullWidth
            multiline
            sx={{ margin: "5px", maxWidth: "95%" }}
          />
        </Grid>
      </Grid>

      <Button onClick={handleSave}>Save Changes</Button>
    </Box>
  );
};

export default EditDetails;
