import React from "react";
import { Box, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

const UnauthorizedPageAccess: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "80vh",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFEFC",
        padding: 3,
        border: "2px solid",
        borderRadius: 2,
        textAlign: "center",
        maxWidth: "700px",
        width: "100%",
      }}
    >
      <LockIcon sx={{ fontSize: 60, color: "", marginBottom: 2 }} />
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Access Restricted
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 3 }} color="error">
        Only authorized users can access this page. Kindly contact support team
        for further information.
      </Typography>
    </Box>
  </Box>
);

export default UnauthorizedPageAccess;
