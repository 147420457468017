export enum ServiceNames {
    outdoor = "outdoor",
    airlines = "airlines",
    airport = "airport",
    newspaper = "newspaper",
    radio = "radio",
    sports = "sports",
    mall = "mall",
    cinema = "cinema",
    digital = "digital",
    transit = "transit",
    magazine = "magazine",
    television = "television",
    btl = "btl"
}

export enum Mediums {
    busShelter = "Bus Shelter",
    unipole = "Unipole",
    billboard = "Bill Board",
}

export const ServiceMediums = {
    outdoor: ['busShelter', 'unipole', 'billboard']
}

export const DigitalMedium = {
  digital: ["digitalscreen", "staticframes"],
};