import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";

const VerifyEmail = () => {
  const history = useNavigate();
  const { token } = useParams();
  const {loginWithEmailToken} = useAuthContext();

  useEffect(() => {
    const verify = async () => {
        try {
            if (!token) return;
            //verify email;
            await loginWithEmailToken(token);
              // redirect to user profile creation
            history("/");
        } catch (error) {
            // redirect to sign up page
        }
  
    };
    verify();
  }, [history, loginWithEmailToken, token]);
  return <div>Verifying Email..</div>;
};

export default VerifyEmail;
