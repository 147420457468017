import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import {
  businessAndFinanceDefaultValues,
  fileFields,
  requiredFields,
  steps,
} from "./vendorConstants";
import getVendorDetails from "../../clients/getVendorDetails";
import { VendorBusinessAndFinance, Vendors } from "./vendorTypes";
import { updateVendorDetails } from "../../clients/updateVendorDetails";
import { isEmailValid, validateField } from "./vendorValidators";
import useHandleEmptyStringValues from "./CustomHook";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import { useAuthContext } from "../../providers/AuthProvider";

interface BusinessAndFinanceProps {
  activeStep: number;
  handleStepChange: (step: number) => Promise<void>;
  vendorId?: string;
}

const BusinessAndFinance = ({
  activeStep,
  handleStepChange,
  vendorId,
}: BusinessAndFinanceProps) => {
  const { authHeader } = useAuthContext();
  const [businessAndFinanceValues, setBusinessAndFinanceValues] =
    useState<VendorBusinessAndFinance>(businessAndFinanceDefaultValues);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasFetchedData = useRef(false);

  const HandleEmptyStringValues = () =>
    useHandleEmptyStringValues({
      values: businessAndFinanceValues,
      setValue: setBusinessAndFinanceValues,
    });

  const handleSnackbar = useCallback((severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const fetchFile = useCallback(async (url: string, field: string) => {
    try {
      const fileResponse = await fetch(url, { method: "GET" });
      if (!fileResponse.ok) {
        throw new Error(`File for ${field} could not be fetched`);
      }
      const arrayBuffer = await fileResponse.arrayBuffer();
      const contentType = fileResponse.headers.get("content-type") || undefined;
      return new File([arrayBuffer], field, { type: contentType });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  }, []);

  const fetchBasicInformationDetails = useCallback(
    async (vendorId: string) => {
      setLoading(true);
      try {
        const response: any = await getVendorDetails(vendorId);
        const vendorDetails: Vendors = response.data.vendorDetails;
        const businessAndFinanceKeys = Object.keys(
          businessAndFinanceDefaultValues
        );

        Object.keys(vendorDetails).forEach((key) => {
          if (
            !businessAndFinanceKeys.includes(
              key as keyof VendorBusinessAndFinance
            )
          )
            delete vendorDetails[key as keyof Vendors];
        });
        const businessAndFinanceData: VendorBusinessAndFinance =
          vendorDetails as VendorBusinessAndFinance;

        const businessAndFinanceFields: (keyof VendorBusinessAndFinance)[] =
          fileFields.filter((field) =>
            businessAndFinanceKeys.includes(
              field as keyof VendorBusinessAndFinance
            )
          ) as (keyof VendorBusinessAndFinance)[];

        for (const field of businessAndFinanceFields) {
          const fieldValue = businessAndFinanceData[field];
          if (fieldValue) {
            const file = await fetchFile(String(fieldValue), field);
            if (file) {
              (businessAndFinanceData[field] as File | string | null) = file;
            }
          }
        }

        setBusinessAndFinanceValues(businessAndFinanceData);
      } catch (error) {
        console.error("Error in fetching basic information : ", error);
        handleSnackbar("error", "Error in fetching basic information");
      } finally {
        setLoading(false);
      }
    },
    [fetchFile, handleSnackbar]
  );

  useEffect(() => {
    if (vendorId && !hasFetchedData.current) {
      fetchBasicInformationDetails(vendorId);
      hasFetchedData.current = true;
    }
  }, [vendorId, fetchBasicInformationDetails]);

  const handleTextInputChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const { name, value } = e.target;
      const modifiedValue = value === "" ? null : value;
      setBusinessAndFinanceValues((prevbusinessAndFinanceValues) => {
        if (name) {
          const newValues = {
            ...prevbusinessAndFinanceValues,
            [name]: modifiedValue,
          };
          return newValues;
        } else {
          return prevbusinessAndFinanceValues;
        }
      });
    },
    []
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleFormNavigationClick = async (step: number) => {
    const requiredFieldsForStep: string[] = requiredFields
      .map((field) => {
        console.log({ field });
        if (Object.keys(businessAndFinanceValues).includes(field)) {
          return field;
        }
        return undefined;
      })
      .filter(
        (field): field is string => field !== undefined && field !== null
      );

    const hasMissingFields = requiredFieldsForStep.some(
      (field) =>
        !validateField(
          businessAndFinanceValues[field as keyof VendorBusinessAndFinance]
        )
    );

    if (hasMissingFields) {
      handleSnackbar("error", "Please fill in all required fields.");
      return;
    }

    HandleEmptyStringValues();

    try {
      for (const [key, value] of Object.entries(businessAndFinanceValues)) {
        if (
          value instanceof File &&
          fileFields.includes(key as keyof VendorBusinessAndFinance)
        ) {
          const image = new FormData();
          image.append(key, value);
          image.append(
            "additionalFileData",
            JSON.stringify({ metadata: "Vendor Document" })
          );
          const imageUploadResponse: any = await uploadFileWithMetadata(image);
          if (imageUploadResponse.ok) {
            const imageUrl = await imageUploadResponse.json();
            businessAndFinanceValues[key as keyof VendorBusinessAndFinance] =
              imageUrl.uploadedFileData.fileUrl as never;
          } else {
            handleSnackbar("error", "Error in uploading documents");
            throw new Error("Error in uploading document");
          }
        }
      }
      try {
        if (vendorId && activeStep < steps.length) {
          if (authHeader)
            await updateVendorDetails(
              vendorId,
              businessAndFinanceValues,
              authHeader
            );
          else throw new Error("User not recognized");
        }
        handleStepChange(step);
      } catch (error) {
        handleSnackbar("error", "Errorin saving vendor details");
        throw new Error(`Error in saving vendor profile details : ${error}`);
      }
    } catch (error) {
      console.error("Error in saving vendor details : ", error);
      handleSnackbar("error", "Error in saving vendor details");
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
        Business and Finanace :
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 90px)"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Business Contact Name"
                name="businessContactName"
                variant="outlined"
                fullWidth
                required
                value={businessAndFinanceValues.businessContactName}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Business Designation"
                name="businessDesignation"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.businessDesignation}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Business Contact Number"
                name="businessContactNumber"
                variant="outlined"
                fullWidth
                required
                value={businessAndFinanceValues.businessContactNumber}
                onChange={handleTextInputChange}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e: React.FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/\D/g, "");
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Business Telephone"
                name="businessTelephone"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.businessTelephone}
                onChange={handleTextInputChange}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e: React.FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/\D/g, "");
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Business Email"
                name="businessEmail"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.businessEmail}
                onChange={handleTextInputChange}
                error={
                  businessAndFinanceValues.businessEmail !== null &&
                  !isEmailValid(businessAndFinanceValues.businessEmail)
                }
                helperText={
                  businessAndFinanceValues.businessEmail !== null &&
                  !isEmailValid(businessAndFinanceValues.businessEmail)
                    ? "Invalid Email Format"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Finance Contact Name"
                name="financeContactName"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.financeContactName}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Finance Designation"
                name="financeDesignation"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.financeDesignation}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Finance Contact Number"
                name="financeContactNumber"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.financeContactNumber}
                onChange={handleTextInputChange}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e: React.FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/\D/g, "");
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Finance Email"
                name="financeEmail"
                variant="outlined"
                fullWidth
                value={businessAndFinanceValues.financeEmail}
                onChange={handleTextInputChange}
                error={
                  businessAndFinanceValues.financeEmail !== null &&
                  businessAndFinanceValues.financeEmail !== "" &&
                  !isEmailValid(businessAndFinanceValues.financeEmail)
                }
                helperText={
                  businessAndFinanceValues.financeEmail !== null &&
                  businessAndFinanceValues.financeEmail !== "" &&
                  !isEmailValid(businessAndFinanceValues.financeEmail)
                    ? "Invalid Email Format"
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: 5 }}>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep + 1)}
            >
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Grid>
        </>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default BusinessAndFinance;
