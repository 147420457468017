export const formatFields = [
    "state",
    "city",
    "medium",
    "type",
    "pincode",
    "area",
    "location",
    "towards",
    "width",
    "height",
    "areaInSqFeet",
    "displayCostPerMonth",
    "longitude",
    "latitude",
    "landmark",
    "vendorId",
    "vendorAssetCode",
    "vendorName",
    "remarks",
    "qna"
];

export const metadataFields = ["city", "location", "landmark", "area"];

export const tagFields = ["type"];

export const mediaDataFormDefaultValues = {
    service: null,
    medium: null,
    type: null,
    areaInSqFeet: null,
    landmark: null,
    location: null,
    latitude: null,
    longitude: null,
    city: null,
    area: null,
    mountingPrice: null,
    printingCharge: null,
    state: null,
    pincode: null,
    displayCostPerMonth: null,
    vendorId: null,
    vendorName: null,
    vendorAssetCode: null,
    towards: null,
    width: null,
    height: null,
};