export const isGstValid = (value: string) => {
    if (!value) return true;
    const gstRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (value.length === 15 && gstRegex.test(value)) return true;
    else if (value.length === 0) return true;
    else return false;
};

export const validateField = (value: any): boolean => {
    return value !== undefined && value !== null && value !== "";
};

export const isPanValid = (value: string) => {
    if (!value) return true;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (value.length === 10 && panRegex.test(value)) return true;
    else if (value.length === 0) return true;
    else return false;
};

export const isTanValid = (value: string) => {
    if (!value) return true;
    const panRegex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (value.length === 10 && panRegex.test(value)) return true;
    else if (value.length === 0) return true;
    else return false;
};

export const isIFSCValid = (ifsc: string): boolean => {
    if (!ifsc) return true;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (ifsc.length === 11 && ifscRegex.test(ifsc)) return true;
    else if (ifsc.length === 0) return true;
    else return false;
};

export const isEmailValid = (email: string): boolean => {
    if (!email) return true;
    const ifscRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (ifscRegex.test(email)) return true;
    else if (email.length === 0) return true;
    else return false;
}

export const removeEmptyString = (obj: any) => {
    Object.entries(obj).forEach(([key, value]) => {
        if (value === "") {
            obj[key] = null;
        }
    });
    return obj;
};
