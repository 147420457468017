import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadGeneralBaseData } from "../../clients/uploadGeneralBaseData";
import { uploadGeneralFinalizedData } from "../../clients/uploadGeneralFinalizedData";
import { uploadGeneralExtendedData } from "../../clients/uploadGeneralExtendedData";
import ErrorIcon from "@mui/icons-material/Error";
import PageTitleComponent from "../PageTitleComponent";
import TableComponent from "./TableComponent";

const CsvFilePreview: React.FC = () => {
  const location = useLocation();
  const { datasetName } = location.state || {};
  const [fileContent, setFileContent] = useState<string[][]>([]);
  const [fileName, setFileName] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const history = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [source, setSource] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);

  const redirectToDashboard = () => {
    history("/");
  };
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files) {
      const file = fileInput.files[0];
      setFileName(file.name);
      setSelectedFile(file); // Store the selected file
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target?.result) {
          const content: string = e.target.result.toString();
          const lines: string[] = content.split("\n");

          const previewData: string[][] = lines.map((line) => {
            const cells: string[] = [];
            let cell = "";
            let insideQuotes = false;

            for (let i = 0; i < line.length; i++) {
              const char = line[i];
              if (char === '"') {
                insideQuotes = !insideQuotes;
              } else if (char === "," && !insideQuotes) {
                cells.push(cell.trim());
                cell = "";
              } else {
                cell += char;
              }
            }

            cells.push(cell.trim()); // Add the last cell
            return cells;
          });

          setFileContent(previewData);
        }
      };

      reader.readAsText(file);
    }
  };

  const handleSubmit = async () => {
    if (fileName && selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("source", source);

        let response: any;
        if (datasetName === "baseData") {
          response = await uploadGeneralBaseData(formData);
        } else if (datasetName === "extendedData") {
          response = await uploadGeneralExtendedData(formData);
        } else if (datasetName === "finalizedData") {
          response = await uploadGeneralFinalizedData(formData);
        }
        if (response && response.status === 200) {
          if (response && response.status === 200) {
            response.json().then((data: any) => {
              const message: string = data.message;
              const conflicts: any = data.conflicts;

              setMessage(message);
              setTableData(conflicts);
            });
          }
          setDialogOpen(true);
        } else {
          setErrorMessage(
            "There was an error uploading the file. Please try again."
          );
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(
          "There was an error uploading the file. Please try again."
        );
      }
    } else {
      setErrorMessage("No file selected");
    }
  };

  const handleDownloadDuplicates = async () => {
    try {
      if (tableData.length > 0) {
        const header = Object.keys(tableData[0]);

        const csvContent = [header.join(",")]
          .concat(
            tableData.map((row: any) =>
              Object.values(row)
                .map((value: any) => {
                  if (typeof value === "string" && value.includes(",")) {
                    return `"${value.replace(/"/g, '""')}"`;
                  }
                  return value;
                })
                .join(",")
            )
          )
          .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "duplicate_download.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
      } else {
        console.error("No Duplicates are there to download");
      }
    } catch (error) {
      console.error("Error downloading selected rows CSV:", error);
    }
  };

  return (
    <Container>
      <PageTitleComponent title="Upload File" subTitle={datasetName} />
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
        <Typography sx={{ color: "red", marginLeft: "5px" }}>
          You have the ability to add new rows of data. However, it's important
          to be aware that the removal of existing rows can only be performed by
          the support team, and you do not have the capability to delete any
          rows yourself.
        </Typography>
      </Box>
      <TextField
        label="Source"
        value={source}
        onChange={(e) => setSource(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <Button onClick={handleSubmit}>Upload</Button>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "300px",
          maxWidth: "80vw",
        }}
      >
        <table
          style={{
            borderCollapse: "collapse",
            width: "auto",
            minWidth: "100%",
          }}
        >
          <tbody>
            {fileContent.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{ border: "1px solid #ccc", padding: "8px" }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          sx={{ "& .MuiDialog-paper": { maxWidth: "95%" } }}
        >
          <DialogContent>
          <Typography variant="h5">{message}</Typography>
            {tableData.length > 0 && (
              <>
                <Typography variant= "h6">Below are the duplicate entries.</Typography>
                <TableComponent tableData={tableData} />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={redirectToDashboard}>Go Back To Dashboard</Button>
            {tableData.length > 0 && (
              <Button onClick={handleDownloadDuplicates}>
                Download duplicates
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert severity="error" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CsvFilePreview;
