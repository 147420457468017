import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { VendorOtherDocuments, Vendors } from "./vendorTypes";
import {
  fileFields,
  steps,
  requiredFields,
  otherDocumentsDefaultValues,
} from "./vendorConstants";
import getVendorDetails from "../../clients/getVendorDetails";
import { updateVendorDetails } from "../../clients/updateVendorDetails";
import { validateField } from "./vendorValidators";
import { VendorFileInput } from "./VendorFileInput";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import useHandleEmptyStringValues from "./CustomHook";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import { useAuthContext } from "../../providers/AuthProvider";

interface OtherDocumentsProps {
  activeStep: number;
  handleStepChange: (step: number) => Promise<void>;
  vendorId?: string;
}

const OtherDocuments = ({
  activeStep,
  handleStepChange,
  vendorId,
}: OtherDocumentsProps) => {
  const { authHeader } = useAuthContext();
  const [otherDocumentsValues, setOtherDocumentsValues] =
    useState<VendorOtherDocuments>(otherDocumentsDefaultValues);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasFetchedData = useRef(false);

  const HandleEmptyStringValues = () =>
    useHandleEmptyStringValues({
      values: otherDocumentsValues,
      setValue: setOtherDocumentsValues,
    });

  const handleSnackbar = useCallback((severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const fetchFile = useCallback(async (url: string, field: string) => {
    try {
      const fileResponse = await fetch(url, { method: "GET" });
      if (!fileResponse.ok) {
        throw new Error(`File for ${field} could not be fetched`);
      }
      const arrayBuffer = await fileResponse.arrayBuffer();
      const contentType = fileResponse.headers.get("content-type") || undefined;
      return new File([arrayBuffer], field, { type: contentType });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  }, []);

  const fetchBasicInformationDetails = useCallback(
    async (vendorId: string) => {
      setLoading(true);
      try {
        const response: any = await getVendorDetails(vendorId);
        const vendorDetails: Vendors = response.data.vendorDetails;
        const docuemntsKeys = Object.keys(otherDocumentsDefaultValues);

        Object.keys(vendorDetails).forEach((key) => {
          if (!docuemntsKeys.includes(key as keyof VendorOtherDocuments))
            delete vendorDetails[key as keyof Vendors];
        });
        const otherOtherDocumentsData: VendorOtherDocuments =
          vendorDetails as VendorOtherDocuments;

        const otherOtherDocumentsFields: (keyof VendorOtherDocuments)[] =
          fileFields.filter((field) =>
            docuemntsKeys.includes(field as keyof VendorOtherDocuments)
          ) as (keyof VendorOtherDocuments)[];

        for (const field of otherOtherDocumentsFields) {
          const fieldValue = otherOtherDocumentsData[field];
          if (fieldValue) {
            const file = await fetchFile(String(fieldValue), field);
            if (file) {
              (otherOtherDocumentsData[field] as File | string | null) = file;
            }
          }
        }
        setOtherDocumentsValues(otherOtherDocumentsData);
      } catch (error) {
        console.error("Error in fetching basic information : ", error);
        handleSnackbar("error", "Error in fetching basic information");
      } finally {
        setLoading(false);
      }
    },
    [fetchFile, handleSnackbar]
  );

  useEffect(() => {
    if (vendorId && !hasFetchedData.current) {
      fetchBasicInformationDetails(vendorId);
      hasFetchedData.current = true;
    }
  }, [vendorId, fetchBasicInformationDetails]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleFormNavigationClick = async (step: number) => {
    const requiredFieldsForStep: string[] = requiredFields
      .map((field) => {
        if (Object.keys(otherDocumentsValues).includes(field)) {
          return field;
        }
        return undefined;
      })
      .filter(
        (field): field is string => field !== undefined && field !== null
      );

    const hasMissingFields = requiredFieldsForStep.some(
      (field) =>
        !validateField(
          otherDocumentsValues[field as keyof VendorOtherDocuments]
        )
    );

    if (hasMissingFields) {
      handleSnackbar("error", "Please fill in all required fields.");
      return;
    }

    HandleEmptyStringValues();

    try {
      for (const [key, value] of Object.entries(otherDocumentsValues)) {
        if (
          value instanceof File &&
          fileFields.includes(key as keyof VendorOtherDocuments)
        ) {
          const image = new FormData();
          image.append(key, value);
          image.append(
            "additionalFileData",
            JSON.stringify({ metadata: "Vendor Document" })
          );
          const imageUploadResponse: any = await uploadFileWithMetadata(image);
          if (imageUploadResponse.ok) {
            const imageUrl = await imageUploadResponse.json();
            otherDocumentsValues[key as keyof VendorOtherDocuments] = imageUrl
              .uploadedFileData.fileUrl as never;
          } else {
            handleSnackbar("error", "Error in uploading documents");
            throw new Error("Error in uploading document");
          }
        }
      }
      try {
        if (vendorId && activeStep < steps.length) {
          if (authHeader)
            await updateVendorDetails(
              vendorId,
              otherDocumentsValues,
              authHeader
            );
          else throw new Error("User not recognized");
        }
        handleStepChange(step);
      } catch (error) {
        handleSnackbar("error", "Errorin saving vendor details");
        throw new Error(`Error in saving vendor profile details : ${error}`);
      }
    } catch (error) {
      console.error("Error in saving vendor details : ", error);
      handleSnackbar("error", "Error in saving vendor details");
    }
  };

  const onFileInputChange = useCallback((name: string, value: File | null) => {
    setOtherDocumentsValues((prevotherDocumentsValues) => {
      if (name && value) {
        const newValues = { ...prevotherDocumentsValues, [name]: value };
        return newValues;
      } else {
        return prevotherDocumentsValues;
      }
    });
  }, []);

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
        OtherDocuments :
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 200px)"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload Other OtherDocuments"
                name="otherOtherDocuments"
                value={otherDocumentsValues.otherOtherDocuments}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload Latest Financials"
                name="latestFinancials"
                value={otherDocumentsValues.latestFinancials}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Form Publisher Edit"
                name="formPublisherEditUrl"
                value={otherDocumentsValues.formPublisherEditUrl}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Form Publisher Doc"
                name="formPublisherDocUrl"
                value={otherDocumentsValues.formPublisherDocUrl}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Form Publisher PDF"
                name="formPublisherPdfUrl"
                value={otherDocumentsValues.formPublisherPdfUrl}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: 5 }}>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep + 1)}
            >
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Grid>
        </>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default OtherDocuments;
