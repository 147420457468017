import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {
  Home as HomeIcon,
  CircleOutlined as CircleOutlinedIcon,
} from "@mui/icons-material";
import ProfileMenu from "./ProfileMenu";
import { useAuthContext } from "../../providers/AuthProvider";
import theme from "../../theme";

const Header: React.FC = () => {
  const location = useLocation();
  const { userDetails, isAuthenticated } = useAuthContext();

  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/home");
  };

  return (
    <Box>
      <header
        style={{
          backgroundColor: theme.palette.primary.main,
          padding: "20px 0",
          textAlign: "center",
          width: "100%",
          top: 0,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          position: "relative",
        }}
      >
        {location.pathname !== "/home" &&
          location.pathname !== "/" &&
          isAuthenticated && (
            <Box sx={{ position: "absolute", left: "20px" }}>
              <HomeButton handleHomeClick={handleHomeClick} />
            </Box>
          )}

        <Typography
          variant="h4"
          sx={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flex: "1",
          }}
          fontWeight="bold"
        >
          GMG CONSOLE
        </Typography>

        {isAuthenticated && <Box sx={{ position: "absolute", right: "20px" }}>
          <ProfileMenu profileData={userDetails} />
        </Box>}
      </header>
      <Box component="main">
        <Outlet />
      </Box>
    </Box>
  );
};

export default Header;

interface HomeButtonProps {
  handleHomeClick: () => void;
}

const HomeButton: React.FC<HomeButtonProps> = ({ handleHomeClick }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={handleHomeClick}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: "30px",
        }}
      >
        <CircleOutlinedIcon
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            fontSize: "40px",
          }}
        />
        <HomeIcon sx={{ fontSize: "20px", zIndex: 1, color: "white" }} />
      </Box>
      <Typography
        variant="h5"
        sx={{
          color: "white",
          marginLeft: "10px",
          zIndex: 1,
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        Home
      </Typography>
    </Box>
  );
};
