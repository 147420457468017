import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { formatDate } from "../../utils/formatDate";
import { VendorBasicInformation, Vendors } from "./vendorTypes";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import getVendorDetails from "../../clients/getVendorDetails";
import {
  basicInformationDefaultValues,
  fileFields,
  requiredFields,
  steps,
} from "./vendorConstants";
import { updateVendorDetails } from "../../clients/updateVendorDetails";
import { isEmailValid, validateField } from "./vendorValidators";
import { VendorFileInput } from "./VendorFileInput";
import useHandleEmptyStringValues from "./CustomHook";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetadata";
import { useAuthContext } from "../../providers/AuthProvider";

export interface StepComponentProps {
  formValues: Vendors;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isField: (fieldName: keyof Vendors) => boolean;
  renderFileUpload: (
    label: string,
    name: keyof Vendors,
    condition: boolean
  ) => JSX.Element;
  error?: string | null;
}

interface BasicInformationProps {
  activeStep: number;
  handleStepChange: (step: number) => Promise<void>;
  vendorId?: string;
}

const BasicInformation = ({
  activeStep,
  handleStepChange,
  vendorId,
}: BasicInformationProps) => {
  const { authHeader } = useAuthContext();
  const [basicInfoValues, setBasicInfoValues] =
    useState<VendorBasicInformation>(basicInformationDefaultValues);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasFetchedData = useRef(false);

  const HandleEmptyStringValues = () =>
    useHandleEmptyStringValues({
      values: basicInfoValues,
      setValue: setBasicInfoValues,
    });

  const handleSnackbar = useCallback((severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const fetchBasicInformationDetails = useCallback(
    async (vendorId: string) => {
      setLoading(true);
      try {
        const response: any = await getVendorDetails(vendorId);
        const vendorDetails: Vendors = response.data.vendorDetails;
        const basicInfoKeys = Object.keys(basicInformationDefaultValues);

        Object.keys(vendorDetails).forEach((key) => {
          if (!basicInfoKeys.includes(key as keyof VendorBasicInformation))
            delete vendorDetails[key as keyof Vendors];
        });
        const basicInfoData: VendorBasicInformation =
          vendorDetails as VendorBasicInformation;

        basicInfoData["incorporationDate"] = String(
          basicInfoData["incorporationDate"]
        ).split("T")[0];

        setBasicInfoValues(basicInfoData);
      } catch (error) {
        console.error("Error in fetching basic information : ", error);
        handleSnackbar("error", "Error in fetching basic information");
      } finally {
        setLoading(false);
      }
    },
    [handleSnackbar]
  );

  useEffect(() => {
    if (vendorId && !hasFetchedData.current) {
      fetchBasicInformationDetails(vendorId);
      hasFetchedData.current = true;
    }
  }, [vendorId, fetchBasicInformationDetails]);

  const handleTextInputChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const { name, value } = e.target;
      const modifiedValue = value === "" ? null : value;
      setBasicInfoValues((prevBasicInfoValues) => {
        if (name) {
          const newValues = { ...prevBasicInfoValues, [name]: modifiedValue };
          return newValues;
        } else {
          return prevBasicInfoValues;
        }
      });
    },
    []
  );

  const onFileInputChange = useCallback((name: string, value: File | null) => {
    setBasicInfoValues((prevBasicInfoValues) => {
      if (name && value) {
        const newValues = { ...prevBasicInfoValues, [name]: value };
        return newValues;
      } else if (name) {
        const newValues = { ...prevBasicInfoValues, [name]: null };
        return newValues;
      } else {
        return prevBasicInfoValues;
      }
    });
  }, []);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleFormNavigationClick = async (step: number) => {
    const requiredFieldsForStep: string[] = requiredFields
      .map((field) => {
        if (Object.keys(basicInfoValues).includes(field)) {
          return field;
        }
        return undefined;
      })
      .filter(
        (field): field is string => field !== undefined && field !== null
      );

    const hasMissingFields = requiredFieldsForStep.some(
      (field) =>
        !validateField(basicInfoValues[field as keyof VendorBasicInformation])
    );

    if (hasMissingFields) {
      handleSnackbar("error", "Please fill in all required fields.");
      return;
    }

    HandleEmptyStringValues();

    try {
      for (const [key, value] of Object.entries(basicInfoValues)) {
        if (
          value instanceof File &&
          fileFields.includes(key as keyof VendorBasicInformation)
        ) {
          const image = new FormData();
          image.append(key, value);
          image.append(
            "additionalFileData",
            JSON.stringify({ metadata: "Vendor Document" })
          );
          const imageUploadResponse: any = await uploadFileWithMetadata(image);
          if (imageUploadResponse.ok) {
            const imageUrl = await imageUploadResponse.json();
            basicInfoValues[key as keyof VendorBasicInformation] = imageUrl
              .uploadedFileData.fileUrl as never;
          } else {
            handleSnackbar("error", "Error in uploading documents");
            throw new Error("Error in uploading document");
          }
        }
      }
      try {
        if (vendorId && activeStep < steps.length) {
          if (authHeader)
            await updateVendorDetails(vendorId, basicInfoValues, authHeader);
          else throw new Error("User not recognized");
        }
        handleStepChange(step);
      } catch (error) {
        handleSnackbar("error", "Errorin saving vendor details");
        throw new Error(`Error in saving vendor profile details : ${error}`);
      }
    } catch (error) {
      console.error("Error in saving vendor details : ", error);
      handleSnackbar("error", "Error in saving vendor details");
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: 4 }}>
        Basic Information :
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 200px)"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                name="vendorEmail"
                variant="outlined"
                fullWidth
                required
                value={basicInfoValues.vendorEmail}
                onChange={handleTextInputChange}
                error={
                  basicInfoValues.vendorEmail !== "" &&
                  !isEmailValid(basicInfoValues.vendorEmail)
                }
                helperText={
                  basicInfoValues.vendorEmail !== "" &&
                  !isEmailValid(basicInfoValues.vendorEmail)
                    ? "Invalid Email Format"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Firm Name"
                name="firmName"
                variant="outlined"
                fullWidth
                required
                value={basicInfoValues.firmName}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Organization Type"
                name="organizationType"
                variant="outlined"
                fullWidth
                value={basicInfoValues.organizationType}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Incorporation Date"
                name="incorporationDate"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formatDate(basicInfoValues.incorporationDate)}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorFileInput
                label="Upload Incorporation Certificate Copy"
                name="incorporationCertCopy"
                value={basicInfoValues.incorporationCertCopy}
                onFileInputChange={onFileInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Proprietor Name"
                name="proprietorName"
                variant="outlined"
                fullWidth
                required
                value={basicInfoValues.proprietorName}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Registered Address"
                name="registeredAddress"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={basicInfoValues.registeredAddress}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Billing Address"
                name="billingAddress"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={basicInfoValues.billingAddress}
                onChange={handleTextInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Shipping Address"
                name="shippingAddress"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={basicInfoValues.shippingAddress}
                onChange={handleTextInputChange}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: 5 }}>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={() => handleFormNavigationClick(activeStep + 1)}
            >
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Grid>
        </>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default BasicInformation;
