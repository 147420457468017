import { fetchWrapper } from "./fetchWrapper";
import { GingerMediaBaseUrl } from "./baseUrls";

export async function updateMediaDetails(service: string, medium: string, gmgAssetCode: string, formValues: any, authHeader: string) {
    return fetchWrapper(`${GingerMediaBaseUrl}/updateMediaDetails?service=${service}&medium=${medium}&gmgAssetCode=${gmgAssetCode}`, {
        method: 'POST',
        headers: {
            Authorization: authHeader,
        },
        body: formValues,
    });
}