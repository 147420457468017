import { createTheme as createMuiTheme, Theme } from '@mui/material/styles'

import paletteBase from './palette-base'
import shadows from './shadows'

// default
const createTheme = (): Theme => {
  return createMuiTheme({
    palette: paletteBase,
    shadows,
  })
}

const theme = createTheme()

export { paletteBase, shadows }

export default theme
