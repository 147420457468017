import React, { useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

interface FilterComponentProps {
  filterData: any;
  cities: string[];
  handleFilterChange: (field: string, value: string) => void;
  handleApplyFilter: () => void;
  handleClearFilters: () => void;
  handleCloseModal: () => void;
}

function FilterComponent({
  filterData,
  cities,
  handleFilterChange,
  handleApplyFilter,
  handleClearFilters,
  handleCloseModal,
}: FilterComponentProps) {
  const [selectedFilter, setSelectedFilter] = useState("city");

  const handleFilterClick = (filter: any) => {
    setSelectedFilter(filter);
  };

  const isFilterApplied = (filterValue: any) => {
    return filterValue && filterValue !== "";
  };

  const renderRightSide = () => {
    switch (selectedFilter) {
      case "city":
        return (
          <>
            <Typography sx={{ fontWeight: "bold" }}>Choose the City</Typography>
            <RadioGroup
              aria-label="city"
              name="city"
              value={filterData?.city || ""}
              onChange={(event) =>
                handleFilterChange("city", event.target.value)
              }
            >
              {cities.map((city) => (
                <FormControlLabel
                  key={city}
                  value={city}
                  control={<Radio />}
                  label={city}
                />
              ))}
            </RadioGroup>
          </>
        );
      case "pincode":
        return (
          <>
            <Typography sx={{ fontWeight: "bold" }}>
              Enter the PINCODE{" "}
            </Typography>
            <TextField
              sx={{ marginRight: "20px", width: "160px" }}
              label="Pincode"
              variant="outlined"
              margin="normal"
              fullWidth
              value={filterData?.pincode || ""}
              size="small"
              onChange={(event) =>
                handleFilterChange("pincode", event.target.value)
              }
            />
          </>
        );
      case "stage":
        return (
          <RadioGroup
            aria-label="stage-label"
            name="stage"
            value={filterData?.stage || ""}
            onChange={(event) =>
              handleFilterChange("stage", event.target.value)
            }
          >
            <FormControlLabel value="base" control={<Radio />} label="Base" />
            <FormControlLabel
              value="extended"
              control={<Radio />}
              label="Extended"
            />
            <FormControlLabel
              value="finalized"
              control={<Radio />}
              label="Finalized"
            />
          </RadioGroup>
        );
      case "flatCount":
        return (
          <Box>
            <TextField
              sx={{ marginRight: "20px", width: "35%" }}
              label="Min Flat Count"
              variant="outlined"
              margin="normal"
              value={filterData?.minFlatCount || ""}
              size="small"
              onChange={(event) =>
                handleFilterChange("minFlatCount", event.target.value)
              }
            />
            <TextField
              sx={{ marginRight: "20px", width: "35%" }}
              label="Max Flat Count"
              variant="outlined"
              margin="normal"
              value={filterData?.maxFlatCount || ""}
              size="small"
              onChange={(event) =>
                handleFilterChange("maxFlatCount", event.target.value)
              }
            />
          </Box>
        );
      case "sortBy":
        return (
          <>
            <Typography sx={{ fontWeight: "bold" }}>
              Choose the Sort Key
            </Typography>
            <RadioGroup
              aria-label="sort-key"
              name="sortKey"
              value={filterData?.sortKey || ""}
              onChange={(event) =>
                handleFilterChange("sortKey", event.target.value)
              }
            >
              <FormControlLabel
                value="numberOfFlats"
                control={<Radio />}
                label="Number Of Flats"
              />
              <FormControlLabel
                value="minPrice"
                control={<Radio />}
                label="Min Price"
              />
              <FormControlLabel
                value="maxPrice"
                control={<Radio />}
                label="Max Price"
              />
            </RadioGroup>
            <Typography sx={{ fontWeight: "bold" }}>
              Choose the order for sorting{" "}
            </Typography>
            <RadioGroup
              aria-label="sort-order"
              name="sortOrder"
              value={filterData?.order || ""}
              onChange={(event) =>
                handleFilterChange("order", event.target.value)
              }
            >
              <FormControlLabel
                value="asc"
                control={<Radio />}
                label="Low to High"
              />
              <FormControlLabel
                value="desc"
                control={<Radio />}
                label="High to Low"
              />
            </RadioGroup>
          </>
        );
      default:
        return null;
    }
  };

  const greenTickIconStyle = {
    position: "absolute",
    color: "green",
    top: "50%",
    right: "5px",
    transform: "translateY(-50%)",
    fontSize: "18px"
  };

  const filterKey = {
    borderBottom: "1px solid #e0e0e0",
    marginRight: "10px",
    position: "relative",
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: "15px 10px 10px 10px",
        display: "flex",
        flexDirection: "column",
        minWidth: "45%",
        minHeight: "60%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
          Apply Filters
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </div>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "35%" }}>
          <MenuItem
            onClick={() => handleFilterClick("city")}
            sx={filterKey}
          >
            City
            {isFilterApplied(filterData?.city) && (
              <DoneIcon sx={greenTickIconStyle} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterClick("pincode")}
            sx={filterKey}
          >
            Pincode
            {isFilterApplied(filterData?.pincode) && (
              <DoneIcon sx={greenTickIconStyle} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterClick("stage")}
            sx={filterKey}
          >
            Stage
            {isFilterApplied(filterData?.stage) && (
              <DoneIcon sx={greenTickIconStyle} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterClick("flatCount")}
            sx={filterKey}
          >
            Flat Count
            {isFilterApplied(filterData?.minFlatCount || filterData?.maxFlatCount) && (
              <DoneIcon sx={greenTickIconStyle} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterClick("sortBy")}
            sx={filterKey}
          >
            Sort By
            {isFilterApplied(filterData?.sortKey || filterData?.order) && (
              <DoneIcon sx={greenTickIconStyle} />
            )}
          </MenuItem>
        </Box>
        <Box>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{ height: "380px" }}
          />
        </Box>
        <Box
          sx={{
            paddingLeft: "10px",
            minHeight: "100%",
          }}
        >
          {renderRightSide()}
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "10px" }} />
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="outlined" color="error" onClick={handleClearFilters}>
          Clear Filters
        </Button>
        <Button variant="outlined" onClick={handleApplyFilter}>
          Apply Filter
        </Button>
      </Box>
    </Box>
  );
}

export default FilterComponent;
