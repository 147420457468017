import JSZip from "jszip";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { serviceTemplates } from "../../utils/serviceTemplates";

export const columnMapper = (columns: string[]): string[] => {
  const mappings: { [key: string]: string } = {
    State: "state",
    City: "city",
    Type: "type",
    Pincode: "pincode",
    Area: "area",
    Location: "location",
    Towards: "towards",
    Width: "width",
    Height: "height",
    "Total Sqft": "areaInSqFeet",
    "Display Cost per Month": "displayCostPerMonth",
    Longitude: "longitude",
    Latitude: "latitude",
    Landmark: "landmark",
    "Vendor Id": "vendorId",
    "Vendor ID": "vendorId",
    "Vendor Asset Code": "vendorAssetCode",
    "Vendor Name": "vendorName",
    Remarks: "remarks",
    "Mounting Price": "mountingPrice",
    "Printing Charge": "printingCharge",
  };

  return columns.map((column) => mappings[column] || column);
};

export const extractImagesFromPptx = async (file: File): Promise<File[]> => {
  const zip = new JSZip();
  const pptxData = await zip.loadAsync(file);
  const images: File[] = [];
  const imageSet = new Set<string>(); // To track unique images

  // A utility function to determine MIME type based on file extension
  const getMimeType = (fileName: string): string => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "jpeg":
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      case "bmp":
        return "image/bmp";
      case "tiff":
        return "image/tiff";
      case "svg":
        return "image/svg+xml";
      default:
        return "application/octet-stream";
    }
  };

  // Get the slide relationships to extract images in the correct order
  const slidePaths = Object.keys(pptxData.files).filter(
    (path) => path.startsWith("ppt/slides/slide") && path.endsWith(".xml")
  );
  slidePaths.sort((a, b) => {
    const getSlideNumber = (path: string) =>
      parseInt(path.match(/slide(\d+)\.xml/)?.[1] || "0", 10);
    return getSlideNumber(a) - getSlideNumber(b);
  });

  for (const slidePath of slidePaths) {
    const slideContent = await pptxData.files[slidePath].async("string");
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(slideContent, "application/xml");

    // Find image references in the slide, excluding background images
    const imageNodes = xmlDoc.getElementsByTagName("a:blip");
    const imageNodesArray = Array.from(imageNodes);

    for (const imageNode of imageNodesArray) {
      const embedAttr = imageNode.getAttribute("r:embed");
      if (embedAttr) {
        const relsPath =
          slidePath.replace("slides/slide", "slides/_rels/slide") + ".rels";
        const relsContent = await pptxData.files[relsPath].async("string");
        const relsXml = parser.parseFromString(relsContent, "application/xml");
        const targetAttr = Array.from(
          relsXml.getElementsByTagName("Relationship")
        )
          .find((rel) => rel.getAttribute("Id") === embedAttr)
          ?.getAttribute("Target");

        if (targetAttr) {
          const imagePath = "ppt/" + targetAttr.replace("../", "");
          if (pptxData.files[imagePath] && !imageSet.has(imagePath)) {
            imageSet.add(imagePath);
            const arrayBuffer = await pptxData.files[imagePath].async(
              "arraybuffer"
            );
            const mimeType = getMimeType(imagePath);

            const imageBlob = new Blob([arrayBuffer], { type: mimeType });
            const imageObjectUrl = URL.createObjectURL(imageBlob);
            const img = new Image();
            img.src = imageObjectUrl;
            await new Promise((resolve) => {
              img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const context = canvas.getContext("2d");
                if (context) {
                  context.drawImage(img, 0, 0, img.width, img.height);
                  const imageData = context.getImageData(
                    0,
                    0,
                    img.width,
                    img.height
                  );

                  let totalBrightness = 0;
                  const pixelCount = imageData.data.length / 4;

                  for (let i = 0; i < imageData.data.length; i += 4) {
                    const r = imageData.data[i];
                    const g = imageData.data[i + 1];
                    const b = imageData.data[i + 2];
                    const brightness = (r + g + b) / 3;
                    totalBrightness += brightness;
                  }

                  const averageBrightness = totalBrightness / pixelCount;
                  const isBlank = averageBrightness > 240;

                  if (!isBlank) {
                    const imageFile = new File([arrayBuffer], imagePath, {
                      type: mimeType,
                    });
                    images.push(imageFile);
                  } else {
                    console.log(
                      `Skipped blank image: ${imagePath} from slide: ${slidePath}`
                    );
                  }
                }
                URL.revokeObjectURL(imageObjectUrl);
                resolve(null);
              };
            });
          }
        }
      }
    }
  }
  console.log(`Total unique images extracted: ${images.length}`);
  return images;
};

const removeEmptyRows = (rows: string[][]) => {
  return rows.filter((row) => row.some((cell) => cell !== null && cell !== ""));
};

const parseCSV = (
  data: string
): { columns: string[]; filteredData: string[][] } => {
  const parsedCSV = Papa.parse<string[]>(data, {
    header: false,
  });
  const columns = parsedCSV.data[0] as string[];
  const filteredData = removeEmptyRows(parsedCSV.data);
  return { columns, filteredData };
};

const parseExcel = (
  data: string
): { columns: string[]; filteredData: string[][] } => {
  const workbook = XLSX.read(data, { type: "binary" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const parsedExcel = XLSX.utils.sheet_to_json<string[]>(sheet, {
    header: 1,
    defval: null,
  });
  const columns = parsedExcel[0] as string[];
  const filteredData = removeEmptyRows(parsedExcel);
  return { columns, filteredData };
};

export const fileParser = async (
  uploadedFile: File
): Promise<{ columns: string[]; filteredData: string[][] }> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      if (e.target) {
        const data = e.target.result as string;
        const parsedFile: { columns: string[]; filteredData: string[][] } =
          uploadedFile.type === "text/csv" ? parseCSV(data) : parseExcel(data);
        resolve(parsedFile);
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read the file"));
    };

    if (uploadedFile.type === "text/csv") {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsBinaryString(uploadedFile);
    }
  });
};

export const handleDownloadSample = (service: string) => {
  if (service === "") return;
  const wb = XLSX.utils.book_new();
  const ws_data = [serviceTemplates[service as keyof typeof serviceTemplates]];
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "Sample");
  XLSX.writeFile(wb, `${service}_sample_template.xlsx`);
};

export const requiredHeadersByMedium: { [key: string]: string[] } = {
  outdoor: [
    "state",
    "city",
    "type",
    "pincode",
    "area",
    "location",
    "towards",
    "width",
    "height",
    "areaInSqFeet",
    "displayCostPerMonth",
    "longitude",
    "latitude",
    "landmark",
    "vendorId",
    "vendorAssetCode",
    "vendorName",
    "remarks",
  ],
  digital: [
    "mediaType",
    "zone",
    "locality",
    "propertyName",
    "pinCode",
    "propertyPriceUpto",
    "screenSize",
    "noOfTowers",
    "noOfScreens",
    "captiveHouseholds",
    "approxReach",
    "noOfImpressionsPerMonth",
    "locationMapLink",
    "latitude",
    "longitude",
    "facilityType",
    "tenantDetails",
    "nccsClass",
    "mediaSiteId",
  ],
};
